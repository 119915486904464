// 定义巴基斯坦语言包对象
export default {
  //公共部分
  'common.cancel': 'منسوخ کریں',
  'common.confirm': 'تصدیق کریں',
  'common.picture.cropping': 'تصویر کاٹیں',
  'common.course.tags': 'کورس کا ٹیگ',
  'common.all.course': 'تمام کورسز',
  'common.article.detail': 'مضمون کی تفصیلات',
  'common.article.public.date': 'اشاعت کی تاریخ',
  'common.article.pre': 'پچھلا مضمون',
  'common.article.next': 'اگلا مضمون',
  'common.reset.password': 'آپ کا پاس ورڈ ری سیٹ کر دیا گیا ہے، براہ کرم فوراً لاگ ان کریں',
  'common.back.home': 'ہوم پیج پر واپس جائیں',
  'common.practice.after.class': 'کلاس کے بعد کی مشق',
  'common.practice.no.after.class': 'کوئی بعد از کلاس مشق نہیں',
  'common.next.course': 'اگلا سبق',
  'common.pre.course': 'پچھلا سبق',
  'common.stop.course': 'روکیں',
  'common.homework.title': 'اسائنمنٹ جمع کر دی گئی ہے',
  'common.homework.teacher.comments': 'استاد کی رائے',
  'common.all.course.charge': 'ادائیگی',
  'common.all.course.free': 'مفت',
  'common.read.agreement': 'براہ کرم صارف معاہدہ پڑھیں اور چیک کریں',
  'common.protokol.privasi01': `میں نے پڑھا اور متفق ہوں`,
  'common.protokol.privasi02': `《صارف معاہدہ》`,
  //头部
  'header.manifesto_01': 'چینی زبان سیکھیں',
  'header.manifesto_02': 'ہان زِہ فینگ',
  'header.menu.homePage': 'ہوم پیج',
  'header.menu.dictionaries': 'لغت',
  'header.menu.allChannels': 'سب',
  'header.login': 'لاگ ان کریں',
  'header.register': 'رجسٹر کریں',
  'header.search.btn': 'تلاش کریں',
  //首页
  'home.ourAdvantage': 'ہماری برتری',
  'home.hotline': 'کسٹمر سروس ای میل',
  'home.contact.immediately': 'فوراً رابطہ کریں',
  'home.back.top': 'اوپر واپس جائیں',
  'home.view.now': 'ابھی بہترین کورس دیکھیں',
  'home.learn.count': 'لوگ ہان زِہ فینگ میں پڑھ رہے ہیں',
  'home.unit.desc':
    'ہم بین الاقوامی چینی تعلیم کے لیے ایک پیشہ ور پلیٹ فارم ہیں، ہم "طالب علم پر مبنی اور معیار پہلے" کے تصور پر قائم ہیں اور ہم چینی ثقافت کو فروغ دینے کے لیے پرعزم ہیں، چین کے درمیان ثقافتی تبادلے اور بیرونی ممالک، اور چینی ثقافت کی ریڑھ کی ہڈی کی طاقت.',
  'home.data.statistics': 'یہ ڈیٹا شماریات ہان زِہ فینگ سے آیا ہے',
  'home.recommended.course': 'تجویز کردہ کورسز',
  'home.recommended.cost-effective': 'اعلیٰ قدر برائے قیمت',
  'home.recommended.high.quality': 'اعلیٰ معیار',
  'home.go.to': 'فوراً جائیں',
  'home.learned.count': '{value} لوگ پہلے ہی سیکھ چکے ہیں',
  'home.course.more': 'زید دیکھیں',
  'home.change.course': 'دوسرا بیچ دیکھیں',
  'home.price.free': 'مفت',
  //底部
  'footer.unit.desc':
    'سرحد پار فاصلے پر چینی بین الاقوامی تعلیم اور چینی ثقافت کے بین الاقوامی فروغ پر توجہ دیں۔ اب تک، کالج نے دنیا بھر کے 110 سے زیادہ ممالک اور خطوں کے تقریباً 10,000 بیرون ملک ادائیگی کرنے والے طلباء کو تربیت دی ہے۔',
  'footer.chinese.course': 'چینی زبان کورس',
  'footer.about.HanFeng': 'ہان زِہ فینگ کے بارے میں',
  'footer.about.us': 'ہمارے بارے میں',
  'footer.privacy.agreement': 'صارف معاہدہ',
  'footer.problem.feedback': 'مسائل کی رائے',
  'footer.global.consulting': 'عالمی مشاورت/شکایت ہاٹ لائن',
  'footer.report.mailbox': 'کسٹمر سروس ای میل',
  'footer.copyright': 'Copyright © 2024 安徽汉之风教育科技有限公司版权所有 {value}：皖ICP备2024055290号',
  'footer.copyright.text': `ویب سائٹ ریکارڈ نمبر`,
  //个人中心
  'individual.center': 'ذاتی مرکز',
  'individual.center.my.homework': 'میرا اسائنمنٹ',
  'individual.center.my.course': 'میرے کورسز',
  'individual.center.my.collection': 'میری پسندیدہ چیزیں',
  'individual.center.log.out': 'لاگ آؤٹ کریں',
  'individual.center.logout.confirm': 'کیا آپ لاگ آؤٹ کرنا چاہتے ہیں؟',
  'individual.update.password': 'پاس ورڈ تبدیل کریں',
  'individual.center.nickname': 'عرفیت',
  'individual.center.submit': 'جمع کرائیں',
  'individual.center.origin.password': 'پرانا پاس ورڈ',
  'individual.center.new.password': 'نیا پاس ورڈ',
  'individual.center.confirm.password': 'پاس ورڈ کی تصدیق کریں',
  'individual.center.all': 'سب کچھ',
  'individual.center.studying': 'سیکھنے میں',
  'individual.center.finished': 'مکمل کیا گیا',
  'individual.center.correcting': 'جانچ پڑتال جاری ہے',
  'individual.center.corrected': 'جانچ پڑتال ہو چکی ہے',
  'individual.center.wart': 'آپ کی اسائنمنٹ کی جانچ پڑتال جاری ہے، براہ کرم صبر کریں۔',
  'individual.favorite.modal.title': 'پسندیدہ چیزوں سے ہٹائیں',
  'individual.favorite.modal.content': 'کیا آپ پسندیدہ چیزوں سے ہٹانا چاہتے ہیں؟',
  //登录弹框
  'login.welcome': 'لاگ ان کرنے پر خوش آمدید',
  'login.welcome.desc': 'ہان زِہ فینگ لرننگ پلیٹ فارم استعمال کرنے پر خوش آمدید',
  'login.forgot.password': 'پاس ورڈ بھول گئے؟',
  'login.sign.now': 'فوراً لاگ ان کریں',
  'login.no.account': 'اکاؤنٹ نہیں ہے؟',
  'login.register.now': 'فوراً رجسٹر کریں',
  'login.register.success': 'رجسٹریشن کامیاب',
  'login.register.success.desc': 'مبارک ہو، آپ کی رجسٹریشن کامیاب رہی',
  'login.not.login': 'ابھی تک لاگ ان نہیں ہوا۔',
  'login.not.login.desc': 'آپ لاگ ان نہیں ہیں، براہ کرم پہلے لاگ ان کریں',
  //注册弹框
  'register.welcome': 'رجسٹر کرنے میں خوش آمدید',
  'register.fetch.code': 'کوڈ حاصل کریں',
  'reset.come.mind': 'یاد آیا',
  //未购买弹框
  'buy.dialog.title': 'آپ نے یہ کورس نہیں خریدا ہے اور براہ کرم پہلے کورس خریدیں۔',
  'buy.dialog.look': 'دوبارہ دیکھو',
  //意见反馈弹框
  'feedback.text': 'اگر آپ پروڈکٹ مینیجر ہوتے تو آپ کے بہترین خیالات کیا ہوں گے؟',
  'feedback.problem': 'موجودہ مسئلہ',
  'feedback.problem.placeholder': 'ہمیں ان مخصوص مسائل کے بارے میں تفصیل سے بتائیں جن کا آپ کو سامنا ہوا اور مخصوص حالات۔',
  'feedback.btn.cancel': 'بعد میں ذکر کریں۔',

  //题目反馈弹框
  'topic.btn.misspellings': 'غلط املا شامل ہے',
  'topic.btn.audio.error': 'آڈیو میں خرابی',
  'topic.btn.not.exist': 'تصویر موجود نہیں',
  'topic.btn.incomplete': 'تصویر موجود نہیں',
  'topic.btn.other': 'تصویر موجود نہیں',
  'topic.problem': 'تفصیلی رائے',
  'topic.problem.text': 'سوالات کی رائے',
  //试听结束弹框
  'try.over.title': 'آزمائشی سیشن ختم',
  'try.over.desc': 'آپ کے مفت آزمائشی سیشن کا وقت ختم ہو چکا ہے، براہ کرم کورس خریدیں',
  //视频课详情
  'detail.course.classify': 'کورس کی قسمیں',
  'detail.tag.video': 'ویڈیو',
  'detail.lessons.number': '{value} اسباق',
  'detail.course.price': 'قیمت',
  'detail.course.characteristic.service': 'خصوصی خدمات',
  'detail.course.brush.questions': 'سوالات کی مشقیں',
  'detail.course.online.consultation': 'آن لائن مشاورت',
  'detail.course.buy.now': 'فوراً خریدیں',
  'detail.course.study.now': 'فوراً خریدیں',
  'detail.course.consult.now': 'ابھی مشورہ کریں',
  'detail.course.course.details': 'کورس کی تفصیلات',
  'detail.course.course.chapter': 'کورس کے ابواب',
  'detail.course.other.recommendations': 'دیگر تجویز کردہ کورسز',
  'detail.course.buy.title': 'آپ ہان زِہ فینگ کا چینی کورس خرید رہے ہیں',
  'detail.welcome.consult': 'مشاورت کے لیے خوش آمدید',
  'detail.consult.tips': 'براہ کرم اپنا رابطہ نمبر دیں، ہمارا ماہر استاد آپ سے رابطہ کرے گا',
  'detail.records.now': 'فوراً رجسٹریشن کریں',
  'detail.records.Play': 'چلائیں',
  'detail.records.try': 'آزمائشی پلے',
  'detail.course.collect': 'پسندیدہ میں شامل کریں',
  'detail.course.uncollect': 'پسندیدہ سے ہٹائیں',

  //视频播放页
  'player.course.collect': 'کورس کو پسندیدہ میں شامل کریں',
  'player.course.download.attachment': 'اٹیچمنٹ ڈاؤن لوڈ کریں',
  'player.course.catalogue': 'فہرست',
  'player.course.electronic.textbook': 'الیکٹرانک تدریسی مواد',
  'player.course.electronic.word': '【الفاظ کی مشق】 بنیادی مشق (آواز، تلفظ وغیرہ)',
  'player.course.chinese.pinyin': 'چینی پینین',
  'player.course.indonesian': 'انڈونیشین زبان',
  'player.course.chinese': 'چینی زبان',
  'player.course.close.pinyin': 'پینین بند کریں',
  'player.course.open.pinyin': 'پینین آن کریں',
  'player.course.electronic.sentence': '【مضمون کی مشق】 بنیادی مشق (مضامین، مختصر جملے وغیرہ)',
  'player.course.exercise': 'کورس کی مشقیں',

  //练习结果页
  'practiceResult.count.questions': 'کل سوالات کی تعداد',
  'practiceResult.count.questions.unanswered': 'بغیر جواب دیے گئے سوالات کی تعداد',
  'practiceResult.answer.sheet': 'جواب کارڈ',
  'practiceResult.answer.correct': 'درست جواب',
  'practiceResult.answer.error': 'غلط جواب',
  'practiceResult.answer.unanswered': 'بغیر جواب دیا گیا',
  'practiceResult.answer.answered': 'جواب دیا گیا',
  'practiceResult.skip.practice': 'مشق کو چھوڑیں',
  'practiceResult.full.mark': 'کل {value} نمبر',
  'practiceResult.submit.now': 'فوراً جمع کرائیں',

  //课程分类
  'all.course.find': `کل <span>{value}</span> نتائج ملے`,
  'all.course.sort.synthesis': 'جامع',
  'all.course.sort.popularity': 'مقبولیت',

  //表单
  'form.placeholder.email': 'براہ کرم اپنا ای میل درج کریں',
  'form.placeholder.password': 'براہ کرم اپنا پاس ورڈ درج کریں',
  'form.placeholder.confirm.password': 'براہ کرم اپنے پاس ورڈ کی تصدیق کریں',
  'form.placeholder.code': 'براہ کرم اپنا تصدیقی کوڈ درج کریں',
  'form.placeholder.nickname': 'براہ کرم اپنا عرفی نام درج کریں',
  'form.placeholder.origin.password': 'براہ کرم اپنا پرانا پاس ورڈ درج کریں',
  'form.placeholder.new.password': 'براہ کرم نیا پاس ورڈ درج کریں',
  'form.placeholder.new.username': 'براہ کرم اپنا نام درج کریں',
  'form.placeholder.new.mobile': 'براہ کرم اپنا فون نمبر درج کریں',
  'form.placeholder.search': 'چینی کورس تلاش کریں',
  'form.placeholder.textarea': 'براہ کرم یہاں جواب دیں',
  'form.reset.password': 'پاس ورڈ ری سیٹ کریں',
  'form.has.account': 'پہلے سے اکاؤنٹ موجود ہے',

  //校验
  'form.password.unlikeness': 'دونوں مرتبہ دیے گئے پاس ورڈ مماثل نہیں',
  'form.email.validate': 'ای میل کا فارمیٹ درست نہیں ہے',
  'form.password.validate': 'پاس ورڈ 8-16 حروف، اعداد یا علامات پر مشتمل ہونا چاہیے',

  //作业、题目
  'exercise.Multiple-choice.question': 'ایک انتخابی سوال',
  'exercise.Multiple.choice.question': 'متعدد انتخابی سوال',
  'exercise.true.false questions': 'درست/غلط سوالات',
  'exercise.subjective.questions': 'موضوعی سوالات',
  'exercise.reading.comprehension.questions': 'ریڈنگ کمپریہنشن',
  'exercise.reading.correct.answer': 'درست جواب',
  'exercise.reading.your.answer': 'آپ کا جواب',
  'exercise.reading.view.parsing': 'وضاحت دیکھیں',
  'exercise.reading.next.question': 'اگلا سوال',
  'exercise.reading.pack.up': 'سمیٹیں',

  //接口返回码
  'code.1001': 'ای میل کا فارمیٹ غلط ہے، براہ کرم درست ای میل درج کریں',
  'code.1004': 'تصدیقی کوڈ درست نہیں ہے',
  'code.1003': 'یہ ای میل پہلے سے رجسٹرڈ ہے، براہ کرم دوسرا ای میل استعمال کریں',
  'code.1011': 'پاس ورڈ کامیابی سے تبدیل ہوگیا',
  'code.1010': 'پرانا پاس ورڈ درست نہیں ہے',
  'code.1009': 'معلومات کامیابی سے تبدیل ہوگئیں',
  'code.1013': 'پاس ورڈ کامیابی سے ری سیٹ ہوگیا',
  'code.1006': 'یوزر نیم یا پاس ورڈ درست نہیں ہے',
  'code.1014': 'مبارک ہو، آپ نے کورس کامیابی سے خرید لیا',
  'code.1016': 'آپ کی مشاورت ہمیں موصول ہو گئی ہے',
  'code.1017': 'رائے کامیابی سے جمع ہو گئی ہے',
  'code.1019': 'آپ کا اسائنمنٹ جمع کر دیا گیا ہے، براہ کرم "میرا اسائنمنٹ" میں چیک کریں',
  //激活
  'code.1028': 'کورس کامیابی سے ایکٹیویٹ ہو گیا',
  'code.1027': 'کورس کو ایکٹیویٹ کرنے میں خرابی',
  'code.1026': 'ایکٹیویشن کوڈ پہلے ہی استعمال ہو چکا ہے',
  'code.1025': 'ایکٹیویشن کوڈ موجود نہیں ہے',

  //中华文化
  'home.latest.news': 'چینی ثقافت',
  'culture.center.more': 'مزید معلومات',
  'culture.center.subTitle': 'چین کو دنیا تک پہنچائیں، دنیا کو چین کے بارے میں آگاہ کریں',
  'culture.center.search': 'براہ کرم مطلوبہ کلیدی لفظ درج کریں',
  'culture.center.text': 'ثقافتی مرکز',
  'culture.center.btn': 'تفصیلات دیکھیں',
  'hzf.login.hint': 'نوٹس',
  'detail.course.download.anytime': 'اٹیچمنٹ ڈاؤن لوڈ کریں',
  'hzf.login.agree': 'متفق ہوں اور لاگ ان کریں',
  'hzf.login.pre.read': 'لاگ ان کرنے سے پہلے براہ کرم پڑھیں اور متفق ہوں',
  'hzf.register.pre.read': 'رجسٹر کرنے سے پہلے براہ کرم پڑھیں اور متفق ہوں',
  'hzf.login.agree.register': 'متفق ہوں اور رجسٹر کریں',

  //词典新增
  'dictionaries.glossary.1': 'ہان زِہ فینگ لغت',
  'dictionaries.glossary.2': 'چینی زبان کی دنیا کو دریافت کریں، اپنی زبان کی راہ روشن کریں',
  'dictionaries.glossary.3': 'لغت مرکز',
  'dictionaries.glossary.4': 'براہ کرم وہ لفظ درج کریں جسے آپ تلاش کرنا چاہتے ہیں',
  'dictionaries.glossary.6': 'پینین (چینی تلفظ)',
  'dictionaries.glossary.7': 'مثال کے جملے',
  'dictionaries.glossary.8': 'انگریزی',
  'dictionaries.glossary.9': 'الفاظ تلاش کریں',
  'dictionaries.glossary.10': 'پہلی سطح',
  'dictionaries.glossary.11': 'دوسری سطح',
  'dictionaries.glossary.12': 'تیسری سطح',
  'dictionaries.glossary.13': 'چوتھی سطح',
  'dictionaries.glossary.14': 'پانچویں سطح',
  'dictionaries.glossary.15': 'چھٹی سطح',
  'currency.symbol': '$',
  //动态替换语言
  'hzf.variable.word': 'انگریزی',

  'activation.code.text01': 'ایکٹیویشن کوڈ کے ذریعے کھولیں',
  'activation.code.text02': 'کورس کو ایکٹیویٹ کرنے کے لیے ایکٹیویشن کوڈ درج کریں',
  'activation.code.text03': 'براہ کرم اپنا ایکٹیویشن کوڈ درج کریں',
  'activation.code.text04': 'خریدنے کے لیے جائیں',
  'activation.code.text05': 'فوراً ایکٹیویٹ کریں',
  'activation.code.text06': 'مبارک ہو! ایکٹیویشن کامیاب ہوئی!',
  'activation.code.text07': 'بعد میں دیکھیں',
  'activation.code.text08': 'آپ نے چینی کورس کامیابی سے ایکٹیویٹ کر لیا ہے، فوراً تجربہ کریں~',

  'video.reload.text': 'دوبارہ دیکھیں',
  'qrcode.text': 'کیو آر کوڈ اسکین کریں، ایپ ڈاؤن لوڈ کریں',

  //我们的优势
  'swiperList.01.title': 'تدریسی کارکردگی اور معیار',
  'swiperList.01.desc': 'اساتذہ کو تدریسی حکمت عملی بہتر بنانے اور معیار بلند کرنے میں مدد ملتی ہے',
  'swiperList.01.desc01': 'اساتذہ تعلیمی ویب سائٹ کے ذریعے تدریسی مواد شیئر، اسائنمنٹ دینے اور چیک کرنے جیسے کام کر سکتے ہیں، جو ان کی کارکردگی کو بہتر بناتا ہے۔',
  'swiperList.02.title': 'لچکدار اور آسان تعلیم',
  'swiperList.02.desc': 'ہان زِہ فینگ چینی روایتی تعلیم کے وقت اور جگہ کی حدود سے آزاد ہے',
  'swiperList.02.desc01':
    'ہان زِہ فینگ چینی بے شمار آن لائن وسائل فراہم کرتا ہے، جسے طلبہ کسی بھی وقت رسائی کر سکتے ہیں اور سیکھنا شروع کر سکتے ہیں، انہیں کسی کورس کے آغاز یا مخصوص وقت کا انتظار نہیں کرنا پڑتا۔',
  'swiperList.03.title': 'ہماری ٹیم',
  'swiperList.03.desc': 'ہان زِہ فینگ چینی میں مختلف شعبوں کے ماہرین پر مشتمل ایک ٹیم ہے',
  'swiperList.03.desc01':
    'ہان زِہ فینگ چینی تعلیم، ٹیکنالوجی، ڈیزائن وغیرہ جیسے شعبوں کے ماہرین کی ٹیم ہے۔ ان کے پاس نہ صرف گہرا علم ہے بلکہ تعلیم کے شعبے کے لیے جذبہ اور ذمہ داری بھی ہے۔ ہر مرحلے میں ٹیم کی محنت اور دانشمندی شامل ہے۔',
  'swiperList.04.title': 'انٹرایکشن اور بات چیت',
  'swiperList.04.desc': 'ہان زِہ فینگ چینی کے کورسز میں طلبہ کے ساتھ فوری انٹرایکشن برقرار رکھا جاتا ہے',
  'swiperList.04.desc01':
    'ہان زِہ فینگ چینی کے کورسز میں طلبہ کے ساتھ فوری انٹرایکشن ہوتا ہے، جبکہ سوالات اور اسائنمنٹ کے ذریعے تعلیمی عمل مکمل کیا جاتا ہے تاکہ طلبہ کی تعلیمی کامیابی یقینی بنائی جا سکے۔',
  'swiperList.05.title': 'ذاتی نوعیت کے سیکھنے کے وسائل کی بھرپور فراہمی',
  'swiperList.05.desc': 'ہان زِہ فینگ چینی نے مختلف چینی تعلیمی وسائل کو یکجا کیا ہے',
  'swiperList.05.desc01':
    'ہان زِہ فینگ چینی مختلف تعلیمی وسائل کو یکجا کرتا ہے، اور ایک سمارٹ ریکومینڈیشن سسٹم کے ذریعے طلبہ کی تعلیمی پیشرفت، دلچسپیوں اور ترجیحات کے مطابق ذاتی نوعیت کا تعلیمی مواد فراہم کرتا ہے، تاکہ سیکھنے کا عمل زیادہ موثر ہو۔',

  //直播课
  'live.message.01': 'لائیو کلاس',
  'live.message.02': 'لائیو کلاس کی تفصیلات',
  'live.message.03': 'بیجنگ کا وقت',
  'live.message.04': 'لائیو کلاس کا استاد',
  'live.message.05': 'لائیو',
  'live.message.06': 'کلاس کا وقت',
  'live.message.07': 'بہترین لائیو کلاسز',
  'live.message.08': 'ماہر اساتذہ کی تعلیم',
  'live.message.09': 'فوری انٹرایکشن',
  'live.message.10': 'لائیو کلاس کی تفصیلات',
  'live.message.11': 'دیگر لائیو کلاسز',
  'live.message.12': 'موجودہ وقت میں براہ راست کلاسز کی کوئی ترتیب نہیں ہے۔',

  //注册加国家
  // 'registry.message.01': 'ایک بار ملکی معلومات کا تعین ہو جائے تو اسے تبدیل نہیں کیا جا سکتا!',
  // 'registry.message.02': 'براہ کرم اپنا ملک منتخب کریں۔',
  // 'registry.message.03': 'ملک',

  //支付
  'payment.message.01': 'ادائیگی کے طریقے',
  'payment.message.02': 'خریداری کی مدت',
  'payment.message.03': 'ادائیگی ناکام! براہ کرم دوبارہ ادائیگی کریں۔',
  'payment.message.04': 'پہلا مہینہ',
  'payment.message.05': 'تیسرا مہینہ',
  'payment.message.06': 'چھٹا مہینہ',
  'payment.message.07': 'پہلا سال',
  'payment.message.08': 'دو سال',

  //添加欧元币种
  'payment.message.09': 'ادائیگی کی کرنسی',
  'payment.message.10': 'امریکی ڈالر',
  'payment.message.11': 'یورو',
  'payment.message.12': '€',
  //支付窗口修改
  'payment.message.13': 'ایک دوستانہ یاد دہانی',
  'payment.message.14': 'PayPal کے ذریعے، موجودہ وقت میں مذکورہ طریقوں سے ادائیگی کی جا سکتی ہے، جو کہ ملک کے لحاظ سے معمولی فرق ہو سکتا ہے۔',
  //购买须知
  'payment.message.15': '"خریداری کی معلومات"',
  'payment.message.16': 'خریداری کی معلومات',
  'payment.message.17': 'میں آگاہ ہوں۔',
  'payment.message.18': 'براہ کرم پہلے پڑھیں اور اتفاق کریں',

  //邀请好友
  'share.message.01': 'دوست کو دعوت دینا',
  'share.message.02': 'فوری طور پر کاپی کریں',
  'share.message.03': 'کاپی کامیاب',
  'share.message.04': 'دعوت کی فہرست',
  'share.message.05': 'مدعو کردہ شخص کا اکاؤنٹ',
  'share.message.06': 'رجسٹریشن کا وقت',

  //版权所有，盗摄必究
  'copyright.message.01': 'کاپی رائٹ',
  'copyright.message.02': 'قانونی تحفظ حاصل ہے',

  //网站名称
  'website.message.01': 'ہانزیفینگ چینی',

  //套餐
  'setMeal.message.01': 'مزید پیکجز',
  'setMeal.message.02': 'پیکج',
  'setMeal.message.03': 'چینی کورسز {value} سیٹوں پر مشتمل ہے۔',
  'setMeal.message.04': `Lکل  نمبر<span>{value}</span> لوگوں نے خریدا ہے۔`,
  'setMeal.message.05': 'خریدا گیا۔',
  'setMeal.message.06': 'پیکیج کی قیمت',
  'setMeal.message.07': 'دیگر پیکیج کی سفارشات',
  'setMeal.message.08': 'تمام پیکجز',
  'setMeal.message.09': 'تمام پیکجز',
  'setMeal.message.10': 'مبارک ہو، آپ نے کامیابی کے ساتھ کورس پیکج  خرید لیا ہے!',
  'setMeal.message.11': 'کورس پیکج',
  //新增套餐
  'setMeal.message.12': 'پیکیج میں اس کورس کا کوٹہ شامل ہے',
  'setMeal.message.13': '{value}اسباق',
  'setMeal.message.14': 'میرا پیکیج',
  'setMeal.message.15': 'اس کورس کے پیکیج کا کوٹہ ختم ہو چکا ہے، کورس کو ان لاک نہیں کیا جا سکتا',
  'setMeal.message.16': 'استعمال شدہ کوٹہ',
  'setMeal.message.17': 'باقی کوٹہ',
  'setMeal.message.18': 'آپ نے دوسرے پیکجز خریدے ہیں۔',

  //我的课程-剩余有效期
  'validity.message.01': 'باقی ماندہ مدت',
  'validity.message.02': '{value} دن',

  //查看更多
  'cultural.message.01': 'زید دیکھیں',

  //添加邮箱
  'email.message.01': 'فروخت سے پہلے کی مشاورت',
  'email.message.02': 'پری سیلز ای میل',
  'email.message.03': 'فروخت کے بعد ای میل',

  //客服悬浮框
  'callCenter.message.01': 'مشاورت',
  //营销弹框
  'marketing.message.01': 'سنا جائے',
  'marketing.message.02': 'بھرپور فوائد',
  'marketing.message.03': 'بڑی تقسیم',
  'marketing.message.04': 'نئے صارفین 7 دنوں کے لیے 14 مفت کلاسز کا لطف اٹھائیں!',
  'marketing.message.05': 'فوراً سنیں',
  'marketing.message.06': 'فوراً وصول کریں',

  //与优化
  'chapter.message.01': 'آپ نے دیکھ لیا ہے',
};
