<template>
  <a-modal
    v-model:visible="visible"
    :cancel-text="cancelText"
    :esc-to-close="false"
    :mask="mask"
    :mask-closable="false"
    :ok-text="okText"
    :on-before-ok="handleOk"
    modal-class="confirm_alert"
    @cancel="handleCancel"
  >
    <template #title>
      {{ title }}
    </template>
    <div v-if="content" class="alert_content">{{ content }}</div>
    <div v-else class="alert_content">
      <slot></slot>
    </div>
  </a-modal>
</template>

<script setup>
import { ref } from 'vue';
import { Modal as AModal } from '@arco-design/web-vue';

const $props = defineProps({
  title: String,
  content: String,
  mask: {
    type: Boolean,
    default: true,
  },
  okText: String,
  cancelText: String,
  cancelEvent: Function,
  okEvent: Function,
});
const $emits = defineEmits(['update:visible']);

const visible = ref(false);

const handleCancel = () => {
  if ($props.cancelEvent) $props.cancelEvent();
};

const handleOk = async () => {
  if ($props.okEvent) await $props.okEvent();
};

const openConfirm = () => {
  visible.value = true;
};

defineExpose({
  openConfirm,
});
</script>

<style lang="less">
.confirm_alert {
  width: 520px;
  min-height: 210px;
  background: #ffffff;
  border-radius: 8px;

  .arco-modal-header {
    border-radius: 8px 8px 0 0;
    height: 50px;
    background: #f5f9fd;
    border: 0;
    font-family: PingFang SC;
    font-weight: 500;
    font-size: 18px;
    color: #333333;
    line-height: 25px;
    text-align: left;
    font-style: normal;
  }

  .arco-modal-body {
    font-family: PingFang SC;
    font-weight: 500;
    font-size: 16px;
    color: #333333;
    line-height: 22px;
    text-align: left;
    font-style: normal;
    min-height: 50px;

    .alert_content {
      font-family: PingFang SC;
      font-weight: 500;
      font-size: 16px;
      color: #333333;
      line-height: 22px;
      text-align: center;
      font-style: normal;
    }
  }

  .arco-modal-footer {
    border: 0;
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;

    .arco-btn:first-child {
      width: 120px;
      height: 36px;
      background: #ffffff;
      border-radius: 19px;
      border: 1px solid #c6c6c6;
      font-family: PingFang SC;
      font-weight: 400;
      font-size: 14px;
      color: #999999;
      line-height: 20px;
      text-align: center;
      font-style: normal;
      margin-left: 60px;
    }

    .arco-btn:last-child {
      width: 120px;
      height: 36px;
      background: #0678ff;
      border-radius: 19px;
      font-family: PingFang SC;
      font-weight: 400;
      font-size: 14px;
      color: #ffffff;
      line-height: 20px;
      text-align: center;
      font-style: normal;
    }
  }
}
</style>
