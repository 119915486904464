import NProgress from "nprogress";
import "nprogress/nprogress.css";
NProgress.configure({
  minimum: 0.1, // 进度条最小值
  showSpinner: true,
  template: `
    <div class="bar" role="bar">
      <div class="peg"></div>
    </div>
    <div class="spinner" role="spinner">
      <div class="spinner-icon"></div>
    </div>
  `,
});
