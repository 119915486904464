import Confirm from '@/components/common/Confirm/Confirm.vue';
import { createApp } from 'vue';
import { use$t } from '@/hooks/language.js';

export default ({ title, content, okText = use$t('common.confirm'), cancelText = use$t('common.cancel'), okEvent, cancelEvent }) => {
  const mountNode = document.createElement('div');
  const instance = createApp(Confirm, {
    title,
    content,
    okText,
    cancelText,
    okEvent,
    cancelEvent,
  });
  document.body.appendChild(mountNode);
  instance.mount(mountNode).openConfirm();
};
