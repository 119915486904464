<!--图片裁剪，上传组件-->
<template>
  <a-modal
    v-model:visible="visible"
    :cancel-text="use$t('common.cancel')"
    :esc-to-close="false"
    :hide-title="false"
    :mask-closable="false"
    :ok-text="use$t('common.confirm')"
    :title="use$t('common.picture.cropping')"
    modal-class="cropper_alert"
    @cancel="handleCancel"
    @ok="handleOk"
  >
    <vueCropper
      ref="cropper"
      :autoCrop="option.autoCrop"
      :autoCropHeight="option.autoCropHeight"
      :autoCropWidth="option.autoCropWidth"
      :canMove="option.canMove"
      :canMoveBox="option.canMoveBox"
      :canScale="option.canScale"
      :centerBox="option.centerBox"
      :enlarge="option.enlarge"
      :fixed="option.fixed"
      :fixedBox="option.fixedBox"
      :fixedNumber="option.fixedNumber"
      :full="option.full"
      :height="option.height"
      :img="url"
      :info="option.info"
      :infoTrue="option.infoTrue"
      :maxImgSize="option.maxImgSize"
      :mode="option.mode"
      :original="option.original"
      :outputSize="option.outputSize"
      :outputType="option.outputType"
      class="cropper"
    ></vueCropper>
  </a-modal>
</template>

<script setup>
import 'vue-cropper/dist/index.css';
import { VueCropper } from 'vue-cropper';
import { Modal as AModal } from '@arco-design/web-vue';
import { reactive, ref } from 'vue';
import { use$t } from '@/hooks/language.js';

const visible = ref(false);
const cropper = ref();
const $props = defineProps({
  url: String,
  success: Function,
});
const option = reactive({
  outputSize: 1, // 裁剪生成图片的质量(可选0.1 - 1)
  outputType: 'png', // 裁剪生成图片的格式（jpeg || png || webp）
  info: true, // 图片大小信息
  canScale: true, // 图片是否允许滚轮缩放
  autoCrop: true, // 是否默认生成截图框
  autoCropWidth: 218, // 默认生成截图框宽度
  autoCropHeight: 218, // 默认生成截图框高度
  fixed: true, // 是否开启截图框宽高固定比例
  fixedNumber: [1, 1], // 截图框的宽高比例
  full: false, // false按原比例裁切图片，不失真
  fixedBox: true, // 固定截图框大小，不允许改变
  canMove: false, // 上传图片是否可以移动
  canMoveBox: true, // 截图框能否拖动
  original: false, // 上传图片按照原始比例渲染
  centerBox: false, // 截图框是否被限制在图片里面
  height: true, // 是否按照设备的dpr 输出等比例图片
  infoTrue: false, // true为展示真实输出图片宽高，false展示看到的截图框宽高
  maxImgSize: 3000, // 限制图片最大宽度和高度
  enlarge: 1, // 图片根据截图框输出比例倍数
});
const handleCancel = () => {};

const handleOk = () => {
  cropper.value.getCropBlob((data) => {
    $props.success(data);
  });
};

const openCropper = () => {
  visible.value = true;
};
defineExpose({
  openCropper,
});
</script>

<style lang="less">
.cropper_alert {
  width: 500px;
  background: #ffffff;
  border-radius: 8px;

  .arco-modal-header {
    border-radius: 8px 8px 0 0;
    height: 50px;
    background: #f5f9fd;
    border: 0;
    display: flex;
    justify-content: flex-end;
  }

  .arco-modal-body {
    font-family: PingFang SC;
    font-weight: 500;
    font-size: 16px;
    color: #333333;
    line-height: 22px;
    text-align: left;
    font-style: normal;
    min-height: 50px;
    padding: 0;

    .cropper {
      width: 100%;
      height: 310px;
    }

    .alert_content {
      font-family: PingFang SC;
      font-weight: 500;
      font-size: 16px;
      color: #333333;
      line-height: 22px;
      text-align: center;
      font-style: normal;
    }
  }

  .arco-modal-footer {
    border: 0;
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;

    .arco-btn:first-child {
      width: 120px;
      height: 36px;
      background: #ffffff;
      border-radius: 19px;
      border: 1px solid #c6c6c6;
      font-family: PingFang SC;
      font-weight: 400;
      font-size: 14px;
      color: #999999;
      line-height: 20px;
      text-align: center;
      font-style: normal;
      margin-left: 60px;
    }

    .arco-btn:last-child {
      width: 120px;
      height: 36px;
      background: #0678ff;
      border-radius: 19px;
      font-family: PingFang SC;
      font-weight: 400;
      font-size: 14px;
      color: #ffffff;
      line-height: 20px;
      text-align: center;
      font-style: normal;
    }
  }
}
</style>
