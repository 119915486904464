/**
 * 注册事件
 */
class BusinessContextEvent {

    eventList = {};

    //注册
    $on(eventName,fn){
            this.$off(eventName).eventList[eventName]=fn;
    }

    //调用
    $emit(eventName,...args){
        if(this.eventList[eventName]){
            this.eventList[eventName](...args);
        }else{
            console.error(eventName+" not exist")
        }
    }

    //销毁
    $off(eventName){
        delete this.eventList[eventName];
        return this;
    }

}

export default new BusinessContextEvent();
