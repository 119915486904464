// 定义中文语言包对象
export default {
  //公共部分
  "common.cancel": "取消",
  "common.confirm": "确定",
  "common.picture.cropping": "图片裁剪",
  "common.course.tags": "课程标签",
  "common.all.course": "全部课程",
  "common.article.detail": "文章详情",
  "common.article.public.date": "发布日期",
  "common.article.pre": "上一篇",
  "common.article.next": "下一篇",
  "common.reset.password": "您的密码已重置，请立即登录",
  "common.back.home": "返回首页",
  "common.practice.after.class": "课后练习",
  "common.practice.no.after.class": "没有课后练习",
  "common.next.course": "下一课",
  "common.pre.course": "上一课",
  "common.stop.course": "停止",
  "common.homework.title": "作业已提交",
  "common.homework.teacher.comments": "老师评语",
  "common.all.course.charge": "付费",
  "common.all.course.free": "免费",
  "common.read.agreement": "请阅读并勾选用户协议",
  "common.protokol.privasi01": `我已阅读并同意`,
  "common.protokol.privasi02": `《用户协议》`,
  //头部
  "header.manifesto_01": "学中文",
  "header.manifesto_02": "汉之风",
  "header.menu.homePage": "首页",
  "header.menu.dictionaries": "词典",
  "header.menu.allChannels": "全部",
  "header.login": "登录",
  "header.register": "注册",
  "header.search.btn": "搜索",
  //首页
  "home.ourAdvantage": "我们的优势",
  "home.hotline": "客服邮箱",
  "home.contact.immediately": "立即联系",
  "home.back.top": "回到顶部",
  "home.view.now": "立即查看精品课程",
  "home.learn.count": "人在汉之风学习",
  "home.unit.desc": "我们是一所国际化汉语教育专业化的平台，坚持“以学生为本、以品质为重“的理念，致力于宣扬中国文化，是海内外中文教育、中外文化交流、中国文化传播的中坚与骨干力量。",
  "home.data.statistics": "该数据统计来自汉之风",
  "home.recommended.course": "推荐课程",
  "home.recommended.cost-effective": "高性价比",
  "home.recommended.high.quality": "高品质",
  "home.go.to": "立即前往",
  "home.learned.count": "{value} 人已学习",
  "home.course.more": "更多",
  "home.change.course": "换一批",
  "home.price.free": "免费",
  //底部
  "footer.unit.desc": "专注于跨境远程华人国际教育和中国文化的国际推广。截至目前，学院已培养了来自全球110多个国家和地区的约10,000名海外付费学生。",
  "footer.chinese.course": "汉语课程",
  "footer.about.HanFeng": "关于汉之风",
  "footer.about.us": "关于我们",
  "footer.privacy.agreement": "用户协议",
  "footer.problem.feedback": "问题反馈",
  "footer.global.consulting": "全球咨询/投诉热线",
  "footer.report.mailbox": "客服邮箱",
  "footer.copyright": "Copyright © 2024 安徽汉之风教育科技有限公司版权所有 {value}：皖ICP备2024055290号",
  "footer.copyright.text": `网站备案编号`,
  //个人中心
  "individual.center": "个人中心",
  "individual.center.my.homework": "我的作业",
  "individual.center.my.course": "我的课程",
  "individual.center.my.collection": "我的收藏",
  "individual.center.log.out": "退出登录",
  "individual.center.logout.confirm": "确定退出吗？",
  "individual.update.password": "修改密码",
  "individual.center.nickname": "昵称",
  "individual.center.submit": "提交",
  "individual.center.origin.password": "原密码",
  "individual.center.new.password": "新密码",
  "individual.center.confirm.password": "确认密码",
  "individual.center.all": "全部",
  "individual.center.studying": "学习中",
  "individual.center.finished": "已学完",
  "individual.center.correcting": "批改中",
  "individual.center.corrected": "已批改",
  "individual.center.wart": "您当前的作业正在批改中，请耐心等待批改。",
  "individual.favorite.modal.title": "取消收藏",
  "individual.favorite.modal.content": "是否取消收藏",
  //登录弹框
  "login.welcome": "欢迎登录",
  "login.welcome.desc": "欢迎您使用汉之风学习平台",
  "login.forgot.password": "忘记密码",
  "login.sign.now": "立即登录",
  "login.no.account": "没有账号",
  "login.register.now": "立即注册",
  "login.register.success": "注册成功",
  "login.register.success.desc": "恭喜您,注册成功",
  "login.not.login": "尚未登录",
  "login.not.login.desc": "您尚未登录，请先登录",
  //注册弹框
  "register.welcome": "欢迎注册",
  "register.fetch.code": "获取验证码",
  "reset.come.mind": "想起来了",
  //未购买弹框
  "buy.dialog.title": "您当前未购买该课程无法进行学习观看，请先购买课程",
  "buy.dialog.look": "再看看",
  //意见反馈弹框
  "feedback.text": "如果你是产品经理，你有何好点子呢？",
  "feedback.problem": "目前问题",
  "feedback.problem.placeholder": "详细说说你遇到的具体问题，具体场景",
  "feedback.btn.cancel": "稍后在提",

  //题目反馈弹框
  "topic.btn.misspellings": "含有错别字",
  "topic.btn.audio.error": "音频错误",
  "topic.btn.not.exist": "图片不存在",
  "topic.btn.incomplete": "题目不完整",
  "topic.btn.other": "其他问题",
  "topic.problem": "具体反馈内容",
  "topic.problem.text": "题目反馈",
  //试听结束弹框
  "try.over.title": "试听结束",
  "try.over.desc": "您的当前免费试听权益已结束，请购买课程后继续观看",
  //视频课详情
  "detail.course.classify": "课程分类",
  "detail.tag.video": "视频",
  "detail.lessons.number": "{value}节课",
  "detail.course.price": "价格",
  "detail.course.characteristic.service": "特色服务",
  "detail.course.brush.questions": "刷题集训",
  "detail.course.online.consultation": "在线咨询",
  "detail.course.buy.now": "立即购买",
  "detail.course.study.now": "立即学习",
  "detail.course.consult.now": "马上咨询",
  "detail.course.course.details": "课程详情",
  "detail.course.course.chapter": "课程章节",
  "detail.course.other.recommendations": "其他课程推荐",
  "detail.course.buy.title": "您正在购买汉之风的汉语课程",
  "detail.welcome.consult": "欢迎咨询",
  "detail.consult.tips": "留下您的联系方式后，我们会有专业的老师联系您",
  "detail.records.now": "立即登记",
  "detail.records.Play": "播放",
  "detail.records.try": "试播",
  "detail.course.collect": "收藏",
  "detail.course.uncollect": "取消收藏",

  //视频播放页
  "player.course.collect": "收藏课程",
  "player.course.download.attachment": "下载附件",
  "player.course.catalogue": "目录",
  "player.course.electronic.textbook": "电子教材",
  "player.course.electronic.word": "【单词练习】基本练习单词（配音、读音等）",
  "player.course.chinese.pinyin": "汉语拼音",
  "player.course.indonesian": "印尼语",
  "player.course.chinese": "汉语",
  "player.course.close.pinyin": "关闭拼音",
  "player.course.open.pinyin": "开启拼音",
  "player.course.electronic.sentence": "【文章练习】基本练习（短文、短句等）",
  "player.course.exercise": "课程练习",

  //练习结果页
  "practiceResult.count.questions": "总题目数",
  "practiceResult.count.questions.unanswered": "未答题题目数",
  "practiceResult.answer.sheet": "答题卡",
  "practiceResult.answer.correct": "答对",
  "practiceResult.answer.error": "答错",
  "practiceResult.answer.unanswered": "未答",
  "practiceResult.answer.answered": "已答",
  "practiceResult.skip.practice": "跳过练习",
  "practiceResult.full.mark": "满分{value}分",
  "practiceResult.submit.now": "立即提交",

  //课程分类
  "all.course.find": `共找到<span>{value}</span>个结果`,
  "all.course.sort.synthesis": "综合",
  "all.course.sort.popularity": "人气",

  //表单
  "form.placeholder.email": "请输入您的邮箱",
  "form.placeholder.password": "请输入您的密码",
  "form.placeholder.confirm.password": "请确认您的密码",
  "form.placeholder.code": "请输入您的验证码",
  "form.placeholder.nickname": "请输入昵称",
  "form.placeholder.origin.password": "请输入原密码",
  "form.placeholder.new.password": "请输入新密码",
  "form.placeholder.new.username": "请输入您的姓名",
  "form.placeholder.new.mobile": "请输入您的电话号码",
  "form.placeholder.search": "搜索汉语课程",
  "form.placeholder.textarea": "请在此处作答",
  "form.reset.password": "重置密码",
  "form.has.account": "已有账号",

  //校验
  "form.password.unlikeness": "两次输入的密码不一致",
  "form.email.validate": "邮箱格式不正确",
  "form.password.validate": "密码为8-16位大小写字母、数字或符号组合",

  //作业、题目
  "exercise.Multiple-choice.question": "单项选择题",
  "exercise.Multiple.choice.question": "多项选择题",
  "exercise.true.false questions": "判断题",
  "exercise.subjective.questions": "主观题",
  "exercise.reading.comprehension.questions": "阅读理解",
  "exercise.reading.correct.answer": "正确答案",
  "exercise.reading.your.answer": "您的答案",
  "exercise.reading.view.parsing": "查看解析",
  "exercise.reading.next.question": "下一题",
  "exercise.reading.pack.up": "收起",

  //接口返回码
  "code.1001": "邮箱格式不对,请输入正确的电子邮箱",
  "code.1004": "验证码不正确",
  "code.1003": "该邮箱已注册,请更换邮箱",
  "code.1011": "密码修改成功",
  "code.1010": "原密码不正确",
  "code.1009": "信息修改成功",
  "code.1013": "重置密码成功",
  "code.1006": "用户名或密码不正确",
  "code.1014": "恭喜您，课程购买成功",
  "code.1016": "您的咨询我们已收到",
  "code.1017": "反馈已提交",
  "code.1019": "您的作业已提交，请前往“我的作业中”查看",
  //激活
  "code.1028": "课程激活成功",
  "code.1027": "激活课程错误",
  "code.1026": "激活码已使用",
  "code.1025": "激活码不存在",

  //中华文化
  "home.latest.news": "中华文化",
  "culture.center.more": "更多消息",
  "culture.center.subTitle": "让中国走向世界，让世界了解中国",
  "culture.center.search": "请输入您需要搜索关键词",
  "culture.center.text": "文化中心",
  "culture.center.btn": "查看详情",
  "hzf.login.hint": "提示",
  "detail.course.download.anytime": "附件下载",
  "hzf.login.agree": "同意并登录",
  "hzf.login.pre.read": "登录前请先阅读并同意",
  "hzf.register.pre.read": "注册前请先阅读并同意",
  "hzf.login.agree.register": "同意并注册",

  //词典新增
  "dictionaries.glossary.1": "汉之风词典",
  "dictionaries.glossary.2": "探索汉语世界，点亮你的语言之路",
  "dictionaries.glossary.3": "词海中心",
  "dictionaries.glossary.4": "请输入您要查询的单词",
  "dictionaries.glossary.6": "拼音",
  "dictionaries.glossary.7": "例句",
  "dictionaries.glossary.8": "英文",
  "dictionaries.glossary.9": "搜索词汇",
  "dictionaries.glossary.10": "一级",
  "dictionaries.glossary.11": "二级",
  "dictionaries.glossary.12": "三级",
  "dictionaries.glossary.13": "四级",
  "dictionaries.glossary.14": "五级",
  "dictionaries.glossary.15": "六级",
  "currency.symbol": "$",
  //动态替换语言
  "hzf.variable.word": "英语",

  "activation.code.text01": "激活码开通",
  "activation.code.text02": "输入激活码激活课程",
  "activation.code.text03": "请输入您的激活码",
  "activation.code.text04": "前往购买",
  "activation.code.text05": "立即激活",
  "activation.code.text06": "恭喜！激活成功!",
  "activation.code.text07": "下次在看",
  "activation.code.text08": "您已成功激活汉语课程，快来体验吧~",

  "video.reload.text": "重新观看",
  "qrcode.text": "扫一扫，下载APP",

  //我们的优势
  "swiperList.01.title": "教学效率与质量",
  "swiperList.01.desc": "帮助教师优化教学策略，提升教学质量",
  "swiperList.01.desc01": "教师可以通过教育网站发布教学资料、布置作业、批改作业等，减少重复性工作，提高工作效率。",
  "swiperList.02.title": "学习灵活便捷",
  "swiperList.02.desc": "汉之风中文不受传统教育的时间和空间限制",
  "swiperList.02.desc01": "汉之风中文提供丰富的在线资源，学生可以随时访问并开始学习，无需等待课程开始或安排特定的学习时间。",
  "swiperList.03.title": "我们的团队",
  "swiperList.03.desc": "汉之风中文由一群来自多个领域的精英组成",
  "swiperList.03.desc01": "汉之风中文由一群来自教育、科技、设计等多个领域的精英组成。他们不仅拥有深厚的专业知识，更对教育事业充满热情与责任感，每一个环节都凝聚着团队成员的心血与智慧。",
  "swiperList.04.title": "互动与交流",
  "swiperList.04.desc": "汉之风中文的课程中保持与学员的实时互动",
  "swiperList.04.desc01": "汉之风中文的课程中保持与学员的实时互动，同时通过题目与课后练习等方式完成学习闭环，确保学员的学习效果。",
  "swiperList.05.title": "丰富的个性化学习资源",
  "swiperList.05.desc": "汉之风中文汇聚了各类中文教育资源",
  "swiperList.05.desc01": "汉之风中文汇聚了各类中文教育资源，通过智能推荐系统，教育网站可以根据学生的学习进度、兴趣偏好等信息，为其推荐个性化的学习内容和路径，提高学习效果。",

  //直播课
  "live.message.01": "直播课",
  "live.message.02": "直播课详情",
  "live.message.03": "北京时间",
  "live.message.04": "直播教师",
  "live.message.05": "直播",
  "live.message.06": "开课时间",
  "live.message.07": "精品课程",
  "live.message.08": "名师教学",
  "live.message.09": "实时互动",
  "live.message.10": "直播详情",
  "live.message.11": "其他直播课",
  "live.message.12": "当前暂未安排直播课程",

  //注册加国家
  // 'registry.message.01': '国家信息一经确定不可更改！',
  // 'registry.message.02': '请选择您的国家',
  // 'registry.message.03': '国家',

  //支付
  "payment.message.01": "支付方式",
  "payment.message.02": "购买时长",
  "payment.message.03": "支付失败！请重新支付",
  "payment.message.04": "1月",
  "payment.message.05": "3月",
  "payment.message.06": "6月",
  "payment.message.07": "1年",
  "payment.message.08": "2年",
  //添加欧元币种
  "payment.message.09": "支付货币",
  "payment.message.10": "美元",
  "payment.message.11": "欧元",
  "payment.message.12": "€",
  //支付窗口修改
  "payment.message.13": "温馨提示",
  "payment.message.14": "通过PayPal，目前支持以上方式支付，根据国家不同会有细微差异。",
  //购买须知
  "payment.message.15": "《购买须知》",
  "payment.message.16": "购买须知",
  "payment.message.17": "我已知悉",
  "payment.message.18": "请先阅读并同意",

  //邀请好友
  "share.message.01": "邀请好友",
  "share.message.02": "立即复制",
  "share.message.03": "复制成功",
  "share.message.04": "邀请列表",
  "share.message.05": "被邀请人账号",
  "share.message.06": "注册时间",

  //版权所有，盗摄必究
  "copyright.message.01": "版权所有",
  "copyright.message.02": "盗摄必究",

  //网站名称
  "website.message.01": "汉之风中文",

  //套餐
  "setMeal.message.01": "更多套餐",
  "setMeal.message.02": "套餐",
  "setMeal.message.03": "包含{value}套汉语课程",
  "setMeal.message.04": `<span>{value}</span>人已购买`,
  "setMeal.message.05": "已购买",
  "setMeal.message.06": "套餐价格",
  "setMeal.message.07": "其他套餐推荐",
  "setMeal.message.08": "包含课程",
  "setMeal.message.09": "全部套餐",
  "setMeal.message.10": "恭喜您，课程套餐购买成功!",
  "setMeal.message.11": "课程套餐",
  //新增套餐
  "setMeal.message.12": "套餐内包含该课程额度",
  "setMeal.message.13": "{value}课时",
  "setMeal.message.14": "我的套餐",
  "setMeal.message.15": "本课程套餐包课程额度已用尽，无法解锁该课程",
  "setMeal.message.16": "已使用额度",
  "setMeal.message.17": "剩余额度",
  "setMeal.message.18": "您已购买其他套餐",

  //我的课程-剩余有效期
  "validity.message.01": "剩余有效期",
  "validity.message.02": "{value}天",

  //查看更多
  "cultural.message.01": "查看更多",

  //添加邮箱
  "email.message.01": "售前咨询",
  "email.message.02": "售前邮箱",
  "email.message.03": "售后邮箱",
  //客服悬浮框
  "callCenter.message.01": "咨询",

  //营销弹框
  "marketing.message.01": "前往试听",
  "marketing.message.02": "福利",
  "marketing.message.03": "大派送",
  "marketing.message.04": "新用户享受课程7天14节免费畅听!",
  "marketing.message.05": "立即领取",
  "marketing.message.06": "立即试听",

  //与优化
  "chapter.message.01": "已观看"
};
