import { createApp } from 'vue';
import Cropper from '@/components/common/Cropper/Cropper.vue';

export default ({ imgUrl, success }) => {
  const mountNode = document.createElement('div');
  const instance = createApp(Cropper, {
    url: imgUrl,
    success: success,
  });
  document.body.appendChild(mountNode);
  instance.mount(mountNode).openCropper();
};
