// 定义中文语言包对象
export default {
  //公共部分
  'common.cancel': 'إلغاء',
  'common.confirm': 'تأكيد',
  'common.picture.cropping': 'قطع الصور',
  'common.course.tags': 'العلامات الدراسيه',
  'common.all.course': 'جميع المحاضرات',
  'common.article.detail': 'شرح المقال بالتفصيل',
  'common.article.public.date': 'تاريخ النشر',
  'common.article.pre': 'المقال السابق',
  'common.article.next': 'المقال التالي',
  'common.reset.password': 'لقد تم ضبط كلمه السر ، الرجاء تسجيل الدخول',
  'common.back.home': 'الرجوع للصفحه الأولي',
  'common.practice.after.class': 'التدريبات',
  'common.practice.no.after.class': 'لاتوجد تدريبات',
  'common.next.course': 'المحاضره التاليه',
  'common.pre.course': 'المحاضره السابقه',
  'common.stop.course': 'وقف',
  'common.homework.title': 'تم تسليم الواجب',
  'common.homework.teacher.comments': 'تعليق الأستاذ',
  'common.all.course.charge': 'مدفوع',
  'common.all.course.free': 'مجاني',
  'common.read.agreement': 'الرجاء القراءه واختيار اتفاقيه المستخدم',
  'common.protokol.privasi01': `لقد قراءت الاتفاقيه ووافقت عليها`,
  'common.protokol.privasi02': `《اتفاقيه المستخدم》`,
  //头部
  'header.manifesto_01': 'دراسه اللغه الصينيه',
  'header.manifesto_02': 'هانزيفنغ',
  'header.menu.homePage': 'الصفحه الأولي',
  'header.menu.dictionaries': 'معجم',
  'header.menu.allChannels': 'الكل',
  'header.login': 'تسجيل الدخول',
  'header.register': 'تسجيل',
  'header.search.btn': 'بحث',
  //首页
  'home.ourAdvantage': 'مميزاتنا',
  'home.hotline': 'خدمه بريد العملاء',
  'home.contact.immediately': 'اتصل في الحال',
  'home.back.top': 'الرجوع الي أعلي',
  'home.view.now': 'إطلع على الدورات الآن',
  'home.learn.count': '0ألف شخص يدرسون في هانغ جر فنغ',
  'home.unit.desc':
    'نحن منصة مهنية دولية لتعليم اللغة الصينية ، ونلتزم بمفهوم "توجيه الإهتمام نحو الطالب ، وتوجيه الإهتمام نحو الجودة" ، ونلتزم بتعزيز الثقافة الصينية ، ونحن نشكل العمود الفقري  للتعليم الصيني ، والتبادلات الثقافية الصينية الأجنبية ، ونشر الثقافة الصينية في الداخل والخارج.',
  'home.data.statistics': 'هذه الإحصائية تأتي من هانغ جر فنغ',
  'home.recommended.course': 'المحاضرات التي يوصي بها',
  'home.recommended.cost-effective': 'أسعار منافسة',
  'home.recommended.high.quality': 'جودة عالية',
  'home.go.to': 'الذهاب فورا',
  'home.learned.count': 'لقد درس بالفعل {value} طلاب',
  'home.course.more': 'شاهد المزيد',
  'home.change.course': 'تغير المجموعه',
  'home.price.free': 'مجاني',
  //底部
  'footer.unit.desc':
    'التركيز على التعليم الدولي الصيني عبر الحدود عن بعد والترويج الوطني للثقافة الصينية. حتى الآن ، قامت الكلية بتدريب حوالي 10000 طالب مدفوع في الخارج من أكثر من 110 دولة ومنطقة حول العالم',
  'footer.chinese.course': 'محاضرات صينيه',
  'footer.about.HanFeng': 'عن هانزيفنغ الصينية',
  'footer.about.us': 'نبذه عننا',
  'footer.privacy.agreement': 'إتفاقيه المستخدم',
  'footer.problem.feedback': 'ردود الفعل عن الأسئلة',
  'footer.global.consulting': 'الاستشارات العالميةو الخط الساخن للشكاوى',
  'footer.report.mailbox': 'بريد خدمه العملاء',
  'footer.copyright': 'Copyright © 2024 安徽汉之风教育科技有限公司版权所有 {value}：皖ICP备2024055290号',
  'footer.copyright.text': `رقم تسجيل الموقع`,
  //个人中心
  'individual.center': 'الصفحه الشخصيه',
  'individual.center.my.homework': 'واجبي',
  'individual.center.my.course': 'محاضراتي',
  'individual.center.my.collection': 'بريدي',
  'individual.center.log.out': 'تسجيل الخروج',
  'individual.center.logout.confirm': 'تاكيد الخروج؟',
  'individual.update.password': 'تعديل كلمه السر',
  'individual.center.nickname': 'الإسم',
  'individual.center.submit': 'تسليم',
  'individual.center.origin.password': 'كلمه السر السابقه',
  'individual.center.new.password': 'كلمه السر الجديده',
  'individual.center.confirm.password': 'تأكيد كلمه السر',
  'individual.center.all': 'الكل',
  'individual.center.studying': 'يدرس',
  'individual.center.finished': 'اكمل الدراسه',
  'individual.center.correcting': 'قيد التصويت ',
  'individual.center.corrected': 'تم التصويت',
  'individual.center.wart': 'انت الان في عمليه التصحيح ، الرجاء الانتظار لحين ان ينتهي التصحيح',
  'individual.favorite.modal.title': 'الغاء المحفوظات',
  'individual.favorite.modal.content': 'هل تريد ان تلغي المحفوظات؟',
  //登录弹框
  'login.welcome': 'مرحبا للتسجيل',
  'login.welcome.desc': 'مرحبا بكم في منصه هانغ جر فنغ لدراسه اللغه الصينيه',
  'login.forgot.password': 'نسيت كلمه السر',
  'login.sign.now': 'التسجيل حالا',
  'login.no.account': 'لايوجد رقم حساب',
  'login.register.now': 'التسجيل الفوري',
  'login.register.success': 'تم التسجيل',
  'login.register.success.desc': 'مبروك ، تم التسجيل بنجاح',
  'login.not.login': 'لم يتم تسجيل الدخول بعد',
  'login.not.login.desc': 'لم تسجل بعد، يرجى تسجيل الدخول أولا ',
  //注册弹框
  'register.welcome': 'مرحبا بالتسجيل',
  'register.fetch.code': 'الحصول علي رمز التاكيد',
  'reset.come.mind': 'لقد تزكرت',
  //未购买弹框
  'buy.dialog.title': 'لا يمكنك مشاهدة المحاضرات لانك لم تقم بالشراء بعد  ، الرجاء الشراء أولاً',
  'buy.dialog.look': 'شاهد مره أخري',
  //意见反馈弹框
  'feedback.text': 'إذا كنت مديرًا للمنتجات، فما هي الأفكار الجيدة التي لديك؟',
  'feedback.problem': 'المشكله السابقه',
  'feedback.problem.placeholder': 'المشكله السابقه',
  'feedback.btn.cancel': 'الرجاء السؤال بعد قليل',

  //题目反馈弹框
  'topic.btn.misspellings': 'تحتوي على كلمات خاطئة',
  'topic.btn.audio.error': 'خطأ صوتي',
  'topic.btn.not.exist': 'الصوره غير موجوده',
  'topic.btn.incomplete': 'العنوان غير مكتمل',
  'topic.btn.other': 'مشاكل أخري',
  'topic.problem': 'خطأ صوتي',
  'topic.problem.text': 'ردود فعل بشأن العنوان',
  //试听结束弹框
  'try.over.title': 'انتهي اختبار السمع',
  'try.over.desc': 'لقد انتهى حقك في الامتحان السمعي المجاني ، يرجى الاستمرار في المشاهدة بعد شراء الدورة.',
  //视频课详情
  'detail.course.classify': 'تصنيف المحاضرات',
  'detail.tag.video': 'فيديو',
  'detail.lessons.number': '{value} محاضرات',
  'detail.course.price': 'السعر',
  'detail.course.characteristic.service': 'الخدمات الخاصه',
  'detail.course.brush.questions': 'التدريب على إعداد الموضوعات',
  'detail.course.online.consultation': 'الاستفسار عبر النت',
  'detail.course.buy.now': 'الشراء الفوري',
  'detail.course.study.now': 'الدراسه الفوريه',
  'detail.course.consult.now': 'الاستفسار الأن',
  'detail.course.course.details': 'شرح المحاضره',
  'detail.course.course.chapter': 'مشاهدة المحاضرات',
  'detail.course.other.recommendations': 'المحاضرات التي نوصي بها',
  'detail.course.buy.title': 'أنت تشتري دورة في اللغة الصينية في المقدمه من هانغ جر فنغ',
  'detail.welcome.consult': 'نرحب بلإستفسارات',
  'detail.consult.tips': 'بعد ترك بيانات الاتصال الخاصة بك ، لدينا معلم محترف للاتصال بك',
  'detail.records.now': 'الدخول حاليا',
  'detail.records.Play': 'تشغيل',
  'detail.records.try': 'تجربة المحاضرات',
  'detail.course.collect': 'حفظ',
  'detail.course.uncollect': 'إلغاء الحفظ',

  //视频播放页
  'player.course.collect': 'حفظ المحاضرات',
  'player.course.download.attachment': 'تحميل المواد',
  'player.course.catalogue': 'المقدمه',
  'player.course.electronic.textbook': 'مواد دراسيه ممغنطه',
  'player.course.electronic.word': '(مراجعه الكلمات)الكلمات الأساسية مثل( الصوتية، النطق)، الخ',
  'player.course.chinese.pinyin': 'حروف كلمات قراءه الرموز الصينيه (pinyin)',
  'player.course.indonesian': 'اللغه الاندنوسيه',
  'player.course.chinese': 'اللغه الصينيه',
  'player.course.close.pinyin': 'إخفاء البين ين (pinyin)',
  'player.course.open.pinyin': 'تشغيل البين ين(pinyin)',
  'player.course.electronic.sentence': 'مراجعه المقال، التدريبات الأساسيه(المقالات القصيره والجمل القصيره الي اخره',
  'player.course.exercise': 'تدريبات الدرس',

  //练习结果页
  'practiceResult.count.questions': 'العدد الإجمالي للمواضيع',
  'practiceResult.count.questions.unanswered': 'عدد المسائل التي لم تجيب عليها',
  'practiceResult.answer.sheet': 'ورقه الاجوبه',
  'practiceResult.answer.correct': 'إجابه صحيحه',
  'practiceResult.answer.error': 'إجابه خاطئه',
  'practiceResult.answer.unanswered': 'لم تجاوب علي السؤال',
  'practiceResult.answer.answered': 'تم الجواب علي السؤال',
  'practiceResult.skip.practice': 'تخطي التدريبات',
  'practiceResult.full.mark': 'درجه كامله{value}درجه',
  'practiceResult.submit.now': 'التسليم حالا',

  //课程分类
  'all.course.find': `تم الحصول علي <span>{value}</span> نتيجه`,
  'all.course.sort.synthesis': 'الكل',
  'all.course.sort.popularity': 'دورات رائجة',

  //表单
  'form.placeholder.email': 'الرجاء ادخال الإيميل',
  'form.placeholder.password': 'الرجاء إدخال كلمه السر',
  'form.placeholder.confirm.password': 'الرجاء تأكيد كلمه السر',
  'form.placeholder.code': 'الرجاء إدخال رمز التأكيد',
  'form.placeholder.nickname': 'الرجاء كتابه الإسم',
  'form.placeholder.origin.password': 'الرجاء إدخال كلمه السر',
  'form.placeholder.new.password': 'الرجاء إدخال كلمه السر الجديده',
  'form.placeholder.new.username': 'الرجاء إدخال الإسم الأول',
  'form.placeholder.new.mobile': 'الرجاء إدخال رقم الهاتف',
  'form.placeholder.search': 'البحث عن محاضرات لغه صينيه',
  'form.placeholder.textarea': 'الرجاء الإجابه علي السؤال',
  'form.reset.password': 'ضبط كلمه السر',
  'form.has.account': 'لدي رقم حساب بالفعل',

  //校验
  'form.password.unlikeness': 'كلمه السر غير مطابقه للاولي',
  'form.email.validate': 'طريقه كتابه الإيميل غير صحيحه',
  'form.password.validate': 'كلمة المرور هي عبارة عن مجموعة من الحروف أو الأرقام أو الرموز تحتوي علي 8-16 رقماً',

  //作业、题目
  'exercise.Multiple-choice.question': 'الأسئله الفرديه',
  'exercise.Multiple.choice.question': 'الأسئله المتعدده',
  'exercise.true.false questions': 'اسأله الحكم',
  'exercise.subjective.questions': 'الأسئله الموضوعيه',
  'exercise.reading.comprehension.questions': 'القراءه والإنشاء',
  'exercise.reading.correct.answer': 'الإجابه الصحيحه',
  'exercise.reading.your.answer': 'إجابتك',
  'exercise.reading.view.parsing': 'رؤيه الشرح',
  'exercise.reading.next.question': 'السؤال التالي',
  'exercise.reading.pack.up': 'جمع',

  //接口返回码
  'code.1001': 'طريقه كتابهة البريد الإلكتروني غير صحيحه، يرجى إدخال البريد الإلكتروني الصحيح',
  'code.1004': 'الرجاء ادخال رمز التأكيد الصحيح',
  'code.1003': 'تم التسجل مسبقا بهذا البريد،الرجاء ادخال بريد اخر',
  'code.1011': 'تم تغير كلمه السر',
  'code.1010': 'كلمه السر غير صحيحه',
  'code.1009': 'تم تغير البيانات بنجاح',
  'code.1013': 'تم ضبط كلمه السر بنجاح',
  'code.1006': 'الإسم او كلمه السر غير صحيحه',
  'code.1014': 'تهانينا لك ، تم شراء المحاضرات بنجاح!',
  'code.1016': 'لقد قمنا بتلقي استفسارك',
  'code.1017': 'تم تسليم ردود الأفعال',
  'code.1019': 'لقد قمنا بتلقي استفسارك',
  //激活
  'code.1028': 'تم تفعيل المحاضرات بنجاح',
  'code.1027': 'هناك خطاء في تفعيل الدروس',
  'code.1026': 'تم إستخدام رمز التفعيل من قبل',
  'code.1025': 'لاوجود لرمز التفعيل ',

  //中华文化
  'home.latest.news': 'الثقافة الصينية',
  'culture.center.more': 'مزيد من الأخبار',
  'culture.center.subTitle': 'دع الصين تجول حول العالم ، دع العالم يتعرف علي الصين',
  'culture.center.search': 'الرجاء إدخال المحتوي الذي تريد البحث عنه',
  'culture.center.text': 'مركز الثقافه',
  'culture.center.btn': 'رؤيه الشرح',
  'hzf.login.hint': 'تنبيه',
  'detail.course.download.anytime': 'تنزيل الملف',
  'hzf.login.agree': 'موافقه ودخول',
  'hzf.login.pre.read': 'قبل الدخول الرجاء القراءه والمتابعه',
  'hzf.register.pre.read': 'قبل التسجيل الرجاء القراءه والموافقه',
  'hzf.login.agree.register': 'اوافق اذهب للتسجيل',

  //词典新增
  'dictionaries.glossary.1': 'معجم هانغ جر فنغ',
  'dictionaries.glossary.2': 'استكشف عالم اللغة الصينية وأضئ رحلتك اللغوية',
  'dictionaries.glossary.3': 'مركز الكلمات',
  'dictionaries.glossary.4': 'الرجاء ادخال الكلمة التي تريد البحث عنها.',
  'dictionaries.glossary.6': 'بين ين',
  'dictionaries.glossary.7': 'الجمل',
  'dictionaries.glossary.8': 'اللغه اللإنجليزيه',
  'dictionaries.glossary.9': 'البحث عن كلمة',
  'dictionaries.glossary.10': 'المستوي الأول',
  'dictionaries.glossary.11': 'المستوي الثاني',
  'dictionaries.glossary.12': 'المستوي الثالث',
  'dictionaries.glossary.13': 'المستوي الرابع',
  'dictionaries.glossary.14': 'المستوي الخامس',
  'dictionaries.glossary.15': 'المستوي السادس',

  'currency.symbol': '$',

  //动态替换语言
  'hzf.variable.word': 'العربية',

  'activation.code.text01': 'تفعيل الرمز للفتح',
  'activation.code.text02': 'أدخل رمز التفعيل لتفعيل الدورة',
  'activation.code.text03': 'أدخل رمز التفعيل الخاص بك',
  'activation.code.text04': 'إذهب للشراء',
  'activation.code.text05': 'التفعيل الآن',
  'activation.code.text06': 'مبروك! تم التفعيل الناجح!',
  'activation.code.text07': 'شاهد مره أخري',
  'activation.code.text08': 'لقد نجحت في تنشيط دورة المحاضرات الصينية ، تعال وجربها ~',

  'video.reload.text': 'إعادة المشاهدة',
  'qrcode.text': ' تنزيل التطبيق',

  //我们的优势
  'swiperList.01.title': 'كفاءة وجودة التدريس',
  'swiperList.01.desc': 'مساعدة المعلمين على تحسين استراتيجيات التدريس وتحسين جودة التدريس',
  'swiperList.01.desc01': 'يمكن للمعلمين نشر المواد التعليمية وتعيين الواجبات المنزلية وتصحيح الواجبات المنزلية وما إلى ذلك من خلال المواقع التعليمية لتقليل العمل المتكرر وتحسين كفاءة العمل.',
  'swiperList.02.title': 'تعلم مرن ومريح',
  'swiperList.02.desc': 'لا يقتصر أسلوب هان الصيني على زمان ومكان التعليم التقليدي.',
  'swiperList.02.desc01': 'يوفر أسلوب هان الصيني ثروة من الموارد عبر الإنترنت التي يمكن للطلاب الوصول إليها وبدء التعلم في أي وقت دون الحاجة إلى انتظار بدء الفصول الدراسية أو ترتيب وقت محدد للدراسة.',
  'swiperList.03.title': 'فريقنا',
  'swiperList.03.desc': 'يتكون أسلوب هان ستايل الصيني من مجموعة من النخب من مختلف المجالات.',
  'swiperList.03.desc01':
    'يتكون اسلوب هان الصيني من مجموعة من النخب من مجالات مختلفة مثل التعليم والتكنولوجيا والتصميم وما إلى ذلك. لم يكن لديهم معرفة مهنية عميقة فحسب، بل لديهم أيضًا شغف وإحساس بالمسؤولية تجاه التعليم العمل الجاد والحكمة لأعضاء الفريق.',
  'swiperList.04.title': 'التفاعل والتواصل',
  'swiperList.04.desc': 'في دورات اسلوب هان الصينية، نحافظ على التفاعل في الوقت الفعلي مع الطلاب',
  'swiperList.04.desc01': 'تحافظ دورات اسلوب هان الصينية على التفاعل في الوقت الفعلي مع الطلاب، وتكمل حلقة التعلم من خلال الأسئلة وتمارين ما بعد الفصل لضمان فعالية تعلم الطلاب.',
  'swiperList.05.title': 'موارد تعليمية شخصية غنية',
  'swiperList.05.desc': 'يجمع موقع اسلوب هان الصينية بين موارد التعليم الصينية المختلفة',
  'swiperList.05.desc01':
    'يجمع موقع اسلوب هان الصينية بين موارد التعليم الصينية المختلفة من خلال نظام التوصية الذكي، يمكن لموقع التعليم أن يوصي بمحتوى ومسارات تعليمية مخصصة للطلاب بناءً على تقدمهم في التعلم وتفضيلاتهم وغيرها من المعلومات لتحسين نتائج التعلم.',

  //直播课
  'live.message.01': 'دروس مباشرة',
  'live.message.02': 'تفاصيل الدروس المباشر',
  'live.message.03': 'توقيت بكين',
  'live.message.04': 'معلمون مباشرون',
  'live.message.05': 'بث مباشر',
  'live.message.06': 'وقت البدء',
  'live.message.07': 'دورات ممتازة',
  'live.message.08': 'التدريس من قبل المعلمين المشهورين',
  'live.message.09': 'التفاعل في الوقت الحقيقي',
  'live.message.10': 'تفاصيل البث المباشر',
  'live.message.11': 'دروس مباشرة اخرى',
  'live.message.12': 'لا توجد دروس مباشرة حاليآ في الجدول.',

  //注册加国家
  // 'registry.message.01': 'معلومات وبيانات الدوله ، لا يمكن تغييرها أبدا!',
  // 'registry.message.02': 'الرجاء إختيار دولتك',
  // 'registry.message.03': 'الدوله',

  //支付
  'payment.message.01': 'طريقه الدفع',
  'payment.message.02': 'مده الشراء',
  'payment.message.03': 'شل الدفع! الرجاء إعاده الدفع',
  'payment.message.04': 'شهر واحد',
  'payment.message.05': 'ثلاثه أشهر',
  'payment.message.06': 'ست أشهر',
  'payment.message.07': 'سنه واحده',
  'payment.message.08': 'سنتان',
  //添加欧元币种
  'payment.message.09': 'العملة المستخدمه للدفع',
  'payment.message.10': 'دولار',
  'payment.message.11': 'يورو',
  'payment.message.12': '€',
  //支付窗口修改
  'payment.message.13': 'تنبيه',
  'payment.message.14': 'من خلال PayPal ، يتم دعم طرق الدفع المذكورة أعلاه حاليا ، وستكون هناك اختلافات طفيفة حسب البلد.',
  //购买须知
  'payment.message.15': '"تعليمات قبل الشراء"',
  'payment.message.16': 'تعليمات قبل الشراء',
  'payment.message.17': 'أنا أعرفها جيدا',
  'payment.message.18': 'الرجاء القراءة ثم الموافقة',

  //邀请好友
  'share.message.01': 'دعوة الأصدقاء',
  'share.message.02': 'انسخ الآن',
  'share.message.03': 'تم النسخ',
  'share.message.04': 'قائمة الدعوات',
  'share.message.05': 'رقم حساب المدعو',
  'share.message.06': 'وقت التسجيل',

  //版权所有，盗摄必究
  'copyright.message.01': 'جميع الحقوق محفوظة',
  'copyright.message.02': 'سيكون هناك تحقيق في السرقة',

  //网站名称
  'website.message.01': 'هانغ جر فنغ الصينيه',

  //套餐
  'setMeal.message.01': 'المزيد من الباقات',
  'setMeal.message.02': 'الباقات',
  'setMeal.message.03': 'يحتوي على {value} مجموعات من دورات اللغة الصينية',
  'setMeal.message.04': `قام <span>{value}</span> شخص بالشراء`,
  'setMeal.message.05': 'تم الشراء',
  'setMeal.message.06': 'سعر الباقة',
  'setMeal.message.07': ' باقات أخرى يوصي بها',
  'setMeal.message.08': 'تشمل الدروس',
  'setMeal.message.09': 'جميع الباقات',
  'setMeal.message.10': 'تهانينا ، لقد اشتريت باقه الدورة التدريبية بنجاح!',
  'setMeal.message.11': 'باقه المحاضرات',
  //新增套餐
  'setMeal.message.12': 'يتم تضمين هذه المحاضرات  في الباقه',
  'setMeal.message.13': '{value}ساعة دراسيه',
  'setMeal.message.14': 'الباقه الخاصة بي',
  'setMeal.message.15': 'تم استخدام حصة الدورة التدريبيه لباقة الدورة التدريبية هذه ولا يمكن فتح هذه الدورة التدريبية.',
  'setMeal.message.16': 'تم الإستخدام بالفعل',
  'setMeal.message.17': 'الرصيد المتبقي',
  'setMeal.message.18': 'لقد اشتريت باقات أخري بالفعل',

  //我的课程-剩余有效期
  'validity.message.01': 'ماتبقي من تاريخ الصلاحية',
  'validity.message.02': '{value}يوما',

  //查看更多
  'cultural.message.01': 'شاهد المزيد',

  //添加邮箱
  'email.message.01': 'استشارة ما قبل البيع',
  'email.message.02': 'صندوق بريد ما قبل البيع',
  'email.message.03': 'صندوق بريد ما بعد البيع',

  //客服悬浮框
  'callCenter.message.01': 'الإستفسارات',
  //营销弹框
  'marketing.message.01': 'الذهاب وتجربة البث',
  'marketing.message.02': 'الهدايا',
  'marketing.message.03': 'تسليم كبير',
  'marketing.message.04': 'يمكن للمستخدمين الجدد الاستمتاع ب 7 أيام من 14 درسا مجانيا!',
  'marketing.message.05': 'الحصول علي المحاضرات',
  'marketing.message.06': 'تشغيل المحاضرات الآن',

  //与优化
  'chapter.message.01': 'تمت القراءة بالفعل',
};
