import Alert from '@/components/common/Alert/Alert.vue';
import { createApp } from 'vue';
import { use$t } from '@/hooks/language.js';

export default ({ title, content, okText = use$t('common.confirm'), okEvent, cancelEvent = () => {} }) => {
  const mountNode = document.createElement('div');
  const instance = createApp(Alert, {
    title,
    content,
    okText,
    okEvent,
    cancelEvent,
  });
  document.body.appendChild(mountNode);
  instance.mount(mountNode).openAlert();
};
