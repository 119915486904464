// 定义西班牙语言包对象
export default {
  //公共部分
  'common.cancel': 'Cancelar',
  'common.confirm': 'Confirmar',
  'common.picture.cropping': 'Recortar imagen',
  'common.course.tags': 'Etiquetas del curso',
  'common.all.course': 'Todos los cursos',
  'common.article.detail': 'Detalles del artículo',
  'common.article.public.date': 'Fecha de publicación',
  'common.article.pre': 'Artículo anterior',
  'common.article.next': 'Siguiente artículo',
  'common.reset.password': 'Su contraseña ha sido restablecida, por favor inicie sesión de inmediato',
  'common.back.home': 'Volver a la página de inicio',
  'common.practice.after.class': 'Ejercicios después de clase',
  'common.practice.no.after.class': 'No hay ejercicios después de clase',
  'common.next.course': 'Siguiente lección',
  'common.pre.course': 'Lección anterior',
  'common.stop.course': 'Detener',
  'common.homework.title': 'Tarea enviada',
  'common.homework.teacher.comments': 'Comentarios del profesor',
  'common.all.course.charge': 'Pago',
  'common.all.course.free': 'Gratis',
  'common.read.agreement': 'Por favor, lea y marque el acuerdo de usuario',
  'common.protokol.privasi01': `He leído y estoy de acuerdo`,
  'common.protokol.privasi02': `"Acuerdo de Usuario"`,
  //头部
  'header.manifesto_01': 'Aprender chino',
  'header.manifesto_02': 'Estilo Han',
  'header.menu.homePage': 'Inicio',
  'header.menu.dictionaries': 'Diccionario',
  'header.menu.allChannels': 'Todo',
  'header.login': 'Iniciar sesión',
  'header.register': 'Registrarse',
  'header.search.btn': 'Buscar',
  //首页
  'home.ourAdvantage': 'Nuestras ventajas',
  'home.hotline': 'Correo de atención al cliente',
  'home.contact.immediately': 'Contactar de inmediato',
  'home.back.top': 'Volver al principio',
  'home.view.now': 'Ver cursos destacados de inmediato',
  'home.learn.count': 'personas están estudiando en Chino Estilo Han',
  'home.unit.desc':
    'Somos una plataforma internacional especializada en la enseñanza del chino, comprometida con el principio de "centrarse en los estudiantes y priorizar la calidad", dedicada a la promoción de la cultura china. Somos una fuerza central en la educación del chino en el extranjero, el intercambio cultural entre China y otros países, y la difusión de la cultura china.',
  'home.data.statistics': 'Los datos provienen de Hanzi Feng',
  'home.recommended.course': 'Cursos recomendados',
  'home.recommended.cost-effective': 'Alta relación calidad-precio',
  'home.recommended.high.quality': 'Alta calidad',
  'home.go.to': 'Ir ahora',
  'home.learned.count': '{value} personas ya han aprendido',
  'home.course.more': 'Quiero aprender más',
  'home.change.course': 'Cambiar',
  'home.price.free': 'Gratis',
  //底部
  'footer.unit.desc':
    'Enfocados en la educación internacional remota para la comunidad china en el extranjero y en la promoción internacional de la cultura china. Hasta la fecha, la academia ha formado a aproximadamente 10,000 estudiantes internacionales de pago de más de 110 países y regiones.',
  'footer.chinese.course': 'Cursos de chino',
  'footer.about.HanFeng': 'Acerca de Estilo Han',
  'footer.about.us': 'Sobre nosotros',
  'footer.privacy.agreement': 'Acuerdo de usuario',
  'footer.problem.feedback': 'Comentarios y sugerencias',
  'footer.global.consulting': 'Línea global de consulta/quejas',
  'footer.report.mailbox': 'Correo de atención al cliente',
  'footer.copyright': 'Copyright © 2024 安徽汉之风教育科技有限公司版权所有 {value}：皖ICP备2024055290号',
  'footer.copyright.text': `Número de registro del sitio web`,
  //个人中心
  'individual.center': 'Centro personal',
  'individual.center.my.homework': 'Mis tareas',
  'individual.center.my.course': 'Mis cursos',
  'individual.center.my.collection': 'Mis favoritos',
  'individual.center.log.out': 'Cerrar sesión',
  'individual.center.logout.confirm': '¿Está seguro de que desea salir?',
  'individual.update.password': 'Cambiar contraseña',
  'individual.center.nickname': 'Apodo',
  'individual.center.submit': 'Enviar',
  'individual.center.origin.password': 'Contraseña actual',
  'individual.center.new.password': 'Nueva contraseña',
  'individual.center.confirm.password': 'Confirmar contraseña',
  'individual.center.all': 'Todo',
  'individual.center.studying': 'Estudiando',
  'individual.center.finished': 'Completado',
  'individual.center.correcting': 'En revisión',
  'individual.center.corrected': 'Revisado',
  'individual.center.wart': 'Actualmente, su tarea está en proceso de revisión, por favor, espere pacientemente.',
  'individual.favorite.modal.title': 'Cancelar favorito',
  'individual.favorite.modal.content': '¿Desea cancelar el favorito',
  //登录弹框
  'login.welcome': 'Bienvenido a iniciar sesión',
  'login.welcome.desc': 'Bienvenido al uso de la plataforma de aprendizaje de Chino Estilo Han',
  'login.forgot.password': 'Olvidé mi contraseña',
  'login.sign.now': 'Iniciar sesión ahora',
  'login.no.account': '¿No tiene cuenta?',
  'login.register.now': 'Regístrese ahora',
  'login.register.success': 'Registro exitoso',
  'login.register.success.desc': 'Felicidades, el registro fue exitoso',
  'login.not.login': 'No ha iniciado sesión',
  'login.not.login.desc': 'Aún no ha iniciado sesión, por favor inicie sesión primero',
  //注册弹框
  'register.welcome': 'Bienvenido a registrarse',
  'register.fetch.code': 'Obtener código de verificación',
  'reset.come.mind': 'Ya lo recordé',
  //未购买弹框
  'buy.dialog.title': 'Actualmente no ha comprado este curso, por lo que no puede acceder a las lecciones. Por favor, compre el curso primero',
  'buy.dialog.look': 'Seguir explorando',
  //意见反馈弹框
  'feedback.text': 'Si usted fuera el gerente de producto, ¿qué buenas ideas tendría?',
  'feedback.problem': 'Problema actual',
  'feedback.problem.placeholder': 'Describa en detalle el problema específico que ha encontrado y el contexto particular',
  'feedback.btn.cancel': 'Preguntar más tarde',

  //题目反馈弹框
  'topic.btn.misspellings': 'Contiene errores tipográficos',
  'topic.btn.audio.error': 'Error de audio',
  'topic.btn.not.exist': 'La imagen no existe',
  'topic.btn.incomplete': 'El enunciado no está completo',
  'topic.btn.other': 'Otro problema',
  'topic.problem': 'Comentarios específicos',
  'topic.problem.text': 'Retroalimentación del enunciado',
  //试听结束弹框
  'try.over.title': 'Fin de la prueba gratuita',
  'try.over.desc': 'Sus derechos de prueba gratuita han finalizado. Por favor, compre el curso para continuar viendo',
  //视频课详情
  'detail.course.classify': 'Categorías de curso',
  'detail.tag.video': 'Video',
  'detail.lessons.number': '{value} lecciones',
  'detail.course.price': 'Precio',
  'detail.course.characteristic.service': 'Servicios especiales',
  'detail.course.brush.questions': 'Entrenamiento intensivo de preguntas',
  'detail.course.online.consultation': 'Consulta en línea',
  'detail.course.buy.now': 'Comprar ahora',
  'detail.course.study.now': 'Empezar a aprender',
  'detail.course.consult.now': 'Consultar ahora',
  'detail.course.course.details': 'Detalles del curso',
  'detail.course.course.chapter': 'Capítulos del curso',
  'detail.course.other.recommendations': 'Otros cursos recomendados',
  'detail.course.buy.title': 'Está comprando un curso de chino en Estilo Han',
  'detail.welcome.consult': 'Bienvenido a consultar',
  'detail.consult.tips': 'Deje su información de contacto y un profesor especializado se pondrá en contacto con usted',
  'detail.records.now': 'Registrar ahora',
  'detail.records.Play': 'Reproducir',
  'detail.records.try': 'Prueba',
  'detail.course.collect': 'Añadir a favoritos',
  'detail.course.uncollect': 'Quitar de favoritos',

  //视频播放页
  'player.course.collect': 'Añadir curso a favoritos',
  'player.course.download.attachment': 'Descargar adjunto',
  'player.course.catalogue': 'Índice',
  'player.course.electronic.textbook': 'Material didáctico digital',
  'player.course.electronic.word': '[Ejercicio de vocabulario] Ejercicios básicos de palabras (doblaje, pronunciación, etc.)',
  'player.course.chinese.pinyin': 'Pinyin chino',
  'player.course.indonesian': 'Indonesio',
  'player.course.chinese': 'Chino',
  'player.course.close.pinyin': 'Desactivar pinyin',
  'player.course.open.pinyin': 'Activar pinyin',
  'player.course.electronic.sentence': '[Ejercicio de lectura] Ejercicios básicos (textos cortos, frases cortas, etc.)',
  'player.course.exercise': 'Ejercicios del curso',

  //练习结果页
  'practiceResult.count.questions': 'Número total de preguntas',
  'practiceResult.count.questions.unanswered': 'Preguntas no respondidas',
  'practiceResult.answer.sheet': 'Tarjeta de respuestas',
  'practiceResult.answer.correct': 'Correcto',
  'practiceResult.answer.error': 'Incorrecto',
  'practiceResult.answer.unanswered': 'No respondido',
  'practiceResult.answer.answered': 'Respondido',
  'practiceResult.skip.practice': 'Saltar ejercicio',
  'practiceResult.full.mark': 'Puntuación máxima {value} puntos',
  'practiceResult.submit.now': 'Enviar ahora',

  //课程分类
  'all.course.find': `Se han encontrado <span>{value}</span> resultados`,
  'all.course.sort.synthesis': 'General',
  'all.course.sort.popularity': 'Popularidad',

  //表单
  'form.placeholder.email': 'Por favor, introduzca su correo electrónico',
  'form.placeholder.password': 'Por favor, introduzca su contraseña',
  'form.placeholder.confirm.password': 'Por favor, confirme su contraseña',
  'form.placeholder.code': 'Por favor, introduzca el código de verificación',
  'form.placeholder.nickname': 'Por favor, introduzca un apodo',
  'form.placeholder.origin.password': 'Por favor, introduzca su contraseña actual',
  'form.placeholder.new.password': 'Por favor, introduzca su nueva contraseña',
  'form.placeholder.new.username': 'Por favor, introduzca su nombre',
  'form.placeholder.new.mobile': 'Por favor, introduzca su número de teléfono',
  'form.placeholder.search': 'Buscar cursos de chino',
  'form.placeholder.textarea': 'Responda aquí',
  'form.reset.password': 'Restablecer contraseña',
  'form.has.account': '¿Ya tiene cuenta',

  //校验
  'form.password.unlikeness': 'Las dos contraseñas ingresadas no coinciden',
  'form.email.validate': 'Formato de correo electrónico incorrecto',
  'form.password.validate': 'La contraseña debe tener entre 8 y 16 caracteres, combinando letras mayúsculas y minúsculas, números o símbolos',

  //作业、题目
  'exercise.Multiple-choice.question': 'Pregunta de opción única',
  'exercise.Multiple.choice.question': 'Pregunta de opción múltiple',
  'exercise.true.false questions': 'Pregunta de verdadero o falso',
  'exercise.subjective.questions': 'Pregunta subjetiva',
  'exercise.reading.comprehension.questions': 'Comprensión de lectura',
  'exercise.reading.correct.answer': 'Respuesta correcta',
  'exercise.reading.your.answer': 'Su respuesta',
  'exercise.reading.view.parsing': 'Ver explicación',
  'exercise.reading.next.question': 'Siguiente pregunta',
  'exercise.reading.pack.up': 'Ocultar',

  //接口返回码
  'code.1001': 'El formato del correo electrónico es incorrecto, por favor ingrese un correo electrónico válido',
  'code.1004': 'El código de verificación es incorrecto',
  'code.1003': 'Este correo electrónico ya está registrado, por favor use otro correo electrónico',
  'code.1011': 'Cambio de contraseña exitoso',
  'code.1010': 'La contraseña actual es incorrecta',
  'code.1009': 'Información modificada con éxito',
  'code.1013': 'Restablecimiento de contraseña exitoso',
  'code.1006': 'El nombre de usuario o la contraseña son incorrectos',
  'code.1014': '¡Felicidades, la compra del curso fue exitosa!',
  'code.1016': 'Hemos recibido su consulta',
  'code.1017': 'Comentarios enviados',
  'code.1019': 'Su tarea ha sido enviada, por favor, vaya a "Mis tareas" para verificar',
  //激活
  'code.1028': 'Curso activado con éxito',
  'code.1027': 'Error al activar el curso',
  'code.1026': 'El código de activación ya ha sido utilizado',
  'code.1025': 'El código de activación no existe',

  //中华文化
  'home.latest.news': 'Cultura china',
  'culture.center.more': 'Más noticias',
  'culture.center.subTitle': 'Llevar a China al mundo, permitir que el mundo conozca China',
  'culture.center.search': 'Por favor, introduzca las palabras clave que desea buscar',
  'culture.center.text': 'Centro cultural',
  'culture.center.btn': 'Ver detalles',
  'hzf.login.hint': 'Aviso',
  'detail.course.download.anytime': 'Descarga de archivo adjunto',
  'hzf.login.agree': 'Aceptar e iniciar sesión',
  'hzf.login.pre.read': 'Por favor, lea y acepte antes de iniciar sesión',
  'hzf.register.pre.read': 'Por favor, lea y acepte antes de registrarse',
  'hzf.login.agree.register': 'Aceptar y registrarse',

  //词典新增
  'dictionaries.glossary.1': 'Diccionario de Estilo Han',
  'dictionaries.glossary.2': 'Explora el mundo del chino y enciende tu camino lingüístico',
  'dictionaries.glossary.3': 'Centro de vocabulario',
  'dictionaries.glossary.4': 'Por favor, introduzca la palabra que desea buscar',
  'dictionaries.glossary.6': 'Pinyin',
  'dictionaries.glossary.7': 'Ejemplo de oración',
  'dictionaries.glossary.8': 'Inglés',
  'dictionaries.glossary.9': 'Buscar vocabulario',
  'dictionaries.glossary.10': 'Nivel 1',
  'dictionaries.glossary.11': 'Nivel 2',
  'dictionaries.glossary.12': 'Nivel 3',
  'dictionaries.glossary.13': 'Nivel 4',
  'dictionaries.glossary.14': 'Nivel 5',
  'dictionaries.glossary.15': 'Nivel 6',
  'currency.symbol': '$',
  //动态替换语言
  'hzf.variable.word': 'Inglés',

  'activation.code.text01': 'Activación del código',
  'activation.code.text02': 'Introduzca el código de activación para activar el curso',
  'activation.code.text03': 'Por favor, introduzca su código de activación',
  'activation.code.text04': 'Ir a comprar',
  'activation.code.text05': 'Activar ahora',
  'activation.code.text06': '¡Felicidades! Activación exitosa',
  'activation.code.text07': 'Ver más tarde',
  'activation.code.text08': 'Ha activado con éxito el curso de chino, ¡pase a experimentar!',

  'video.reload.text': 'Ver de nuevo',
  'qrcode.text': 'Escanee el código para descargar la APP',

  //我们的优势
  'swiperList.01.title': 'Eficiencia y calidad docente',
  'swiperList.01.desc': 'Ayudar a los profesores a optimizar las estrategias de enseñanza y mejorar la calidad de la enseñanza.',
  'swiperList.01.desc01':
    'Los profesores pueden publicar materiales didácticos, asignar tareas, corregir tareas, etc. a través de sitios web educativos para reducir el trabajo repetitivo y mejorar la eficiencia laboral.',
  'swiperList.02.title': 'Aprendizaje flexible y conveniente',
  'swiperList.02.desc': 'Estilo Han no está limitado por el tiempo y el espacio de la educación tradicional',
  'swiperList.02.desc01':
    'Estilo Han ofrece una amplia gama de recursos en línea que los estudiantes pueden acceder y comenzar a aprender en cualquier momento, sin necesidad de esperar a que inicie un curso o de programar un horario específico de estudio',
  'swiperList.03.title': 'Nuestro equipo',
  'swiperList.03.desc': 'Chino Estilo Han está compuesto por un grupo de élite de múltiples disciplinas',
  'swiperList.03.desc01':
    'El equipo de Estilo Han incluye expertos en educación, tecnología, diseño y otros campos. No solo poseen un profundo conocimiento profesional, sino que también están llenos de pasión y responsabilidad por la educación. Cada detalle refleja el esfuerzo y la sabiduría del equipo.',
  'swiperList.04.title': 'Interacción y comunicación',
  'swiperList.04.desc': 'Los cursos de Chino Estilo Han mantienen la interacción en tiempo real con los estudiantes',
  'swiperList.04.desc01':
    'Los cursos de Chino Estilo Han mantienen la interacción en tiempo real con los estudiantes, y se completan con ejercicios y tareas para cerrar el ciclo de aprendizaje, asegurando la efectividad del estudio.',
  'swiperList.05.title': 'Amplios recursos de aprendizaje personalizados',
  'swiperList.05.desc': 'Estilo Han reúne diversos recursos educativos en chino',
  'swiperList.05.desc01':
    'Chino Estilo Han reúne una variedad de recursos educativos en chino y, mediante un sistema de recomendación inteligente, el sitio web educativo puede sugerir contenidos y rutas de aprendizaje personalizadas según el progreso, intereses y preferencias del estudiante, mejorando así la efectividad del aprendizaje.',

  //直播课
  'live.message.01': 'Clases en vivo',
  'live.message.02': 'Detalles de la clase en vivo',
  'live.message.03': 'Hora de Pekín',
  'live.message.04': 'Profesor en vivo',
  'live.message.05': 'Clase en vivo',
  'live.message.06': 'Hora de inicio del curso',
  'live.message.07': 'Clase en vivo de calidad',
  'live.message.08': 'Enseñanza de maestros expertos',
  'live.message.09': 'Interacción en tiempo real',
  'live.message.10': 'Detalles de la transmisión en vivo',
  'live.message.11': 'Otras clases en vivo',
  'live.message.12': 'Actualmente no hay clases en vivo programadas',

  //注册加国家
  // 'registry.message.01': '国家信息一经确定不可更改！',
  // 'registry.message.02': '请选择您的国家',
  // 'registry.message.03': '国家',

  //支付
  'payment.message.01': 'Método de pago',
  'payment.message.02': 'Duración de la compra',
  'payment.message.03': '¡Pago fallido! Por favor, intente de nuevo',
  'payment.message.04': 'Un mes',
  'payment.message.05': 'Tres meses',
  'payment.message.06': 'Seis meses',
  'payment.message.07': 'Un año',
  'payment.message.08': 'Dos años',
  //添加欧元币种
  'payment.message.09': 'Moneda de pago',
  'payment.message.10': 'Dólares',
  'payment.message.11': 'Euros',
  'payment.message.12': '€',
  //支付窗口修改
  'payment.message.13': 'Consejos cálidos',
  'payment.message.14': 'Actualmente se admite pago a través de paypal, que tendrá cambios según el país',
  //购买须知
  'payment.message.15': '"Instrucciones de compra"',
  'payment.message.16': 'Instrucciones de compra',
  'payment.message.17': 'Lo sé',
  'payment.message.18': 'Por favor, lea y acepte',

  //邀请好友
  'share.message.01': 'Invitar a amigos',
  'share.message.02': 'Copiar ahora',
  'share.message.03': 'Copiado con éxito',
  'share.message.04': 'Lista de invitados',
  'share.message.05': 'Cuenta del invitado',
  'share.message.06': 'Fecha de registro',

  //版权所有，盗摄必究
  'copyright.message.01': 'Todos los derechos de autor',
  'copyright.message.02': 'Protección legal',

  //网站名称
  'website.message.01': 'Chino Estilo Han',

  //套餐
  'setMeal.message.01': 'Más paquetes',
  'setMeal.message.02': 'Paquete',
  'setMeal.message.03': 'Incluye {value} conjuntos de cursos de chino',
  'setMeal.message.04': `<span>{value}</span> personas han comprado`,
  'setMeal.message.05': 'Comprado',
  'setMeal.message.06': 'Precio del paquete',
  'setMeal.message.07': 'Recomendación de otros paquetes',
  'setMeal.message.08': 'Incluye cursos',
  'setMeal.message.09': 'Todos los paquetes',
  'setMeal.message.10': '¡Felicitaciones, ¡ el paquete del curso se compró con éxito!',
  'setMeal.message.11': 'Paquete curricular',
  //新增套餐
  'setMeal.message.12': 'El paquete contiene la cuota del curso',
  'setMeal.message.13': '{value} horas de clase',
  'setMeal.message.14': 'Mi paquete',
  'setMeal.message.15': 'El paquete de este curso se ha agotado y no se puede desbloquear el curso',
  'setMeal.message.16': 'Cuota utilizada',
  'setMeal.message.17': 'Cuota restante',
  'setMeal.message.18': 'Ha comprado otros paquetes',

  //我的课程-剩余有效期
  'validity.message.01': 'Período de validez restante',
  'validity.message.02': '{value} días',

  //查看更多
  'cultural.message.01': 'Ver más',

  //添加邮箱
  'email.message.01': 'Consulta previa a la venta',
  'email.message.02': 'Buzón de preventa',
  'email.message.03': 'Buzón post - venta',

  //客服悬浮框
  'callCenter.message.01': 'Consulta',
  //营销弹框
  'marketing.message.01': 'Ir a la sección de audición',
  'marketing.message.02': 'Beneficios',
  'marketing.message.03': 'Gran entrega',
  'marketing.message.04': '¡Los nuevos usuarios disfrutan de 7 días y 14 sesiones de escucha gratuita!',
  'marketing.message.05': 'Recoger de inmediato',
  'marketing.message.06': 'Prueba inmediata',

  //与优化
  'chapter.message.01': 'Ya se ha visto',
};
