import { ref } from 'vue';
import { defineStore } from 'pinia';

/**
 * 缓存在本地的
 */
export const useLocalCacheStore = defineStore(
  'localCacheStore',
  () => {
    const defaultLanguage = ref('');
    const userinfo = ref();

    const asyncSetLanguage = async (value) => {
      defaultLanguage.value = value;
    };

    /**
     * 设置用户信息
     * @param value
     */
    const setUserInfo = (value) => {
      userinfo.value = value;
    };

    /**
     * 更新用户信息
     * @param value
     */
    const updUserInfo = (value) => {
      Object.assign(userinfo.value, value);
    };

    return {
      defaultLanguage,
      asyncSetLanguage,
      userinfo,
      setUserInfo,
      updUserInfo,
    };
  },
  {
    persist: {
      enabled: true,
      strategies: [{ storage: localStorage }],
    },
  }
);
