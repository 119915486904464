// 定义法语语言包对象
export default {
  //公共部分
  'common.cancel': 'Annuler',
  'common.confirm': 'Bien sûr',
  'common.picture.cropping': "Recadrage d'image",
  'common.course.tags': 'Balises de cours',
  'common.all.course': 'Tous les cours',
  'common.article.detail': "Détails de l'article",
  'common.article.public.date': 'date de sortie',
  'common.article.pre': 'Article précédent',
  'common.article.next': 'Article suivant',
  'common.reset.password': 'Votre mot de passe a été réinitialisé, veuillez vous connecter maintenant',
  'common.back.home': "Retour à la page d'accueil",
  'common.practice.after.class': 'Exercices après les cours',
  'common.practice.no.after.class': "Pas d'exercices après l'école",
  'common.next.course': 'prochaine leçon',
  'common.pre.course': 'Leçon précédente',
  'common.stop.course': 'arrêt',
  'common.homework.title': 'Devoir soumis',
  'common.homework.teacher.comments': 'Commentaires des enseignants',
  'common.all.course.charge': 'Payer',
  'common.all.course.free': 'gratuit',
  'common.read.agreement': "Veuillez lire et vérifier les conditions d'utilisation",
  'common.protokol.privasi01': `J'ai lu et j'accepte`,
  'common.protokol.privasi02': `"Contrat d'utilisation"`,
  //头部
  'header.manifesto_01': 'apprendre le chinois',
  'header.manifesto_02': 'Style Han',
  'header.menu.homePage': 'première page',
  'header.menu.dictionaries': 'dictionnaire',
  'header.menu.allChannels': 'tous',
  'header.login': 'Se connecter',
  'header.register': 'registre',
  'header.search.btn': 'recherche',
  //首页
  'home.ourAdvantage': 'Nos avantages',
  'home.hotline': 'E-mail du service client',
  'home.contact.immediately': 'Contactez maintenant',
  'home.back.top': 'retour en haut',
  'home.view.now': 'Découvrez les cours premium maintenant',
  'home.learn.count': 'personnes ont étudié à hanzfeng',
  'home.unit.desc':
    "Nous sommes une plate-forme professionnelle pour l'éducation chinoise internationale. Nous adhérons au concept « axé sur l'étudiant et la qualité d'abord » et nous nous engageons à promouvoir la culture chinoise. Nous sommes l'épine dorsale de l'éducation chinoise au pays et à l'étranger et des échanges culturels entre la Chine. et les pays étrangers, et la diffusion de la culture chinoise.",
  'home.data.statistics': 'Les statistiques viennent de hanzfeng',
  'home.recommended.course': 'Cours recommandés',
  'home.recommended.cost-effective': 'Performances à coût élevé',
  'home.recommended.high.quality': 'haute qualité',
  'home.go.to': 'Vas-y maintenant',
  'home.learned.count': ' personnes ont appris',
  'home.course.more': 'je veux toujours apprendre',
  'home.change.course': 'Changer un lot',
  'home.price.free': 'gratuit',
  //底部
  'footer.unit.desc':
    "Focus sur l’éducation internationale chinoise à distance transfrontalière et la promotion internationale de la culture chinoise. Jusqu'à présent, le collège a formé environ 10 000 étudiants étrangers payants provenant de plus de 110 pays et régions du monde.",
  'footer.chinese.course': 'Cours de chinois',
  'footer.about.HanFeng': 'À propos de hanzfeng',
  'footer.about.us': 'à propos de nous',
  'footer.privacy.agreement': "Contrat d'utilisation",
  'footer.problem.feedback': 'Commentaires sur les problèmes',
  'footer.global.consulting': 'Hotline mondiale de consultation/réclamation',
  'footer.report.mailbox': 'E-mail du service client',
  'footer.copyright': 'Copyright © 2024 安徽汉之风教育科技有限公司版权所有 {value}：皖ICP备2024055290号',
  'footer.copyright.text': `Numéro d'enregistrement du site Web`,
  //个人中心
  'individual.center': 'Centre personnel',
  'individual.center.my.homework': 'mes devoirs',
  'individual.center.my.course': 'mes cours',
  'individual.center.my.collection': 'ma collection',
  'individual.center.log.out': 'Se déconnecter',
  'individual.center.logout.confirm': 'Êtes-vous sûr de vouloir quitter?',
  'individual.update.password': 'Changer le mot de passe',
  'individual.center.nickname': 'Surnom',
  'individual.center.submit': 'soumettre',
  'individual.center.origin.password': "Mot de passe d'origine",
  'individual.center.new.password': 'Nouveau mot de passe',
  'individual.center.confirm.password': 'Confirmez le mot de passe',
  'individual.center.all': 'tous',
  'individual.center.studying': 'Étudier',
  'individual.center.finished': 'Complété',
  'individual.center.correcting': 'En cours de correction',
  'individual.center.corrected': 'Corrigé',
  'individual.center.wart': 'Vous êtes actuellement en cours de correction de devoirs, veuillez attendre patiemment la correction.',
  'individual.favorite.modal.title': 'Annuler les favoris',
  'individual.favorite.modal.content': "S'il faut annuler la collecte",
  //登录弹框
  'login.welcome': 'Bienvenue pour vous connecter',
  'login.welcome.desc': "Bienvenue sur la plateforme d'apprentissage hanzfeng",
  'login.forgot.password': 'oublier le mot de passe',
  'login.sign.now': 'Connectez-vous maintenant',
  'login.no.account': 'Pas de compte',
  'login.register.now': 'Inscrivez-vous maintenant',
  'login.register.success': 'Inscription réussie',
  'login.register.success.desc': 'Félicitations, votre inscription est réussie',
  'login.not.login': 'Pas encore connecté',
  'login.not.login.desc': "Vous n'êtes pas connecté, veuillez d'abord vous connecter",
  //注册弹框
  'register.welcome': 'Bienvenue pour vous inscrire',
  'register.fetch.code': 'Obtenir le code de vérification',
  'reset.come.mind': 'Rappelé',
  //未购买弹框
  'buy.dialog.title': "Vous n'avez pas acheté ce cours actuellement et ne pouvez pas l'apprendre ni le regarder. Veuillez d'abord l'acheter.",
  'buy.dialog.look': 'Regarde encore',
  //意见反馈弹框
  'feedback.text': 'Si vous étiez chef de produit, quelles seraient vos meilleures idées?',
  'feedback.problem': 'problème actuel',
  'feedback.problem.placeholder': 'Parlez-nous en détail des problèmes spécifiques que vous avez rencontrés et des scénarios spécifiques.',
  'feedback.btn.cancel': 'Mentionnez-le plus tard',

  //题目反馈弹框
  'topic.btn.misspellings': 'Contient des fautes de frappe',
  'topic.btn.audio.error': 'erreur audio',
  'topic.btn.not.exist': "L'image n'existe pas",
  'topic.btn.incomplete': 'La question est incomplète',
  'topic.btn.other': 'Autres questions',
  'topic.problem': 'Contenu de feedback spécifique',
  'topic.problem.text': 'Commentaires sur les questions',
  //试听结束弹框
  'try.over.title': 'Fin des auditions',
  'try.over.desc': 'Votre essai gratuit actuel est terminé. Veuillez acheter le cours pour continuer à le regarder.',
  //视频课详情
  'detail.course.classify': 'Classement des cours',
  'detail.tag.video': 'vidéo',
  'detail.lessons.number': '{value} leçons',
  'detail.course.price': 'prix',
  'detail.course.characteristic.service': 'Prestations spéciales',
  'detail.course.brush.questions': 'Formation au brossage des questions',
  'detail.course.online.consultation': 'Consultation en ligne',
  'detail.course.buy.now': 'Achetez-le maintenant',
  'detail.course.study.now': 'Apprenez maintenant',
  'detail.course.consult.now': 'Consultez maintenant',
  'detail.course.course.details': 'Détails du cours',
  'detail.course.course.chapter': 'Chapitre du cours',
  'detail.course.other.recommendations': 'Autres recommandations de cours',
  'detail.course.buy.title': 'Vous achetez un cours de chinois chez hanzfeng',
  'detail.welcome.consult': 'Bienvenue à consulter',
  'detail.consult.tips': 'Après avoir laissé vos coordonnées, un professeur professionnel vous contactera.',
  'detail.records.now': 'Inscrivez-vous maintenant',
  'detail.records.Play': 'jouer',
  'detail.records.try': 'pilote',
  'detail.course.collect': 'collecter',
  'detail.course.uncollect': 'Annuler les favoris',

  //视频播放页
  'player.course.collect': 'Enregistrer le cours',
  'player.course.download.attachment': 'Télécharger la pièce jointe',
  'player.course.catalogue': 'Table des matières',
  'player.course.electronic.textbook': 'Manuels électroniques',
  'player.course.electronic.word': '[Pratique des mots] Pratique des mots de base (doublage, prononciation, etc.)',
  'player.course.chinese.pinyin': 'Pinyin chinois',
  'player.course.indonesian': 'indonésien',
  'player.course.chinese': 'Chinois',
  'player.course.close.pinyin': 'Fermer le pinyin',
  'player.course.open.pinyin': 'Activer le Pinyin',
  'player.course.electronic.sentence': "[Pratique de l'article] Pratique de base (articles courts, phrases courtes, etc.)",
  'player.course.exercise': 'Exercices du cours',

  //练习结果页
  'practiceResult.count.questions': 'Nombre total de questions',
  'practiceResult.count.questions.unanswered': 'Nombre de questions sans réponse',
  'practiceResult.answer.sheet': 'feuille de réponses',
  'practiceResult.answer.correct': 'répondre',
  'practiceResult.answer.error': 'Mauvaise réponse',
  'practiceResult.answer.unanswered': 'Pas de réponse',
  'practiceResult.answer.answered': 'Répondu',
  'practiceResult.skip.practice': "sauter l'exercice",
  'practiceResult.full.mark': 'Score complet {value} points',
  'practiceResult.submit.now': 'Soumettre maintenant',

  //课程分类
  'all.course.find': `<span>{value}</span> résultats trouvés`,
  'all.course.sort.synthesis': 'complet',
  'all.course.sort.popularity': 'Popularité',

  //表单
  'form.placeholder.email': 'Veuillez entrer votre email',
  'form.placeholder.password': 'Veuillez entrer votre mot de passe',
  'form.placeholder.confirm.password': 'Veuillez confirmer votre mot de passe',
  'form.placeholder.code': 'Veuillez entrer votre code de vérification',
  'form.placeholder.nickname': 'Veuillez entrer un pseudo',
  'form.placeholder.origin.password': "Veuillez saisir le mot de passe d'origine",
  'form.placeholder.new.password': 'Veuillez entrer un nouveau mot de passe',
  'form.placeholder.new.username': 'Veuillez entrer votre nom',
  'form.placeholder.new.mobile': 'Veuillez entrer votre numéro de téléphone',
  'form.placeholder.search': 'Rechercher des cours de chinois',
  'form.placeholder.textarea': 'Merci de répondre ici',
  'form.reset.password': 'réinitialiser le mot de passe',
  'form.has.account': 'Vous avez déjà un compte',

  //校验
  'form.password.unlikeness': 'Les mots de passe saisis deux fois sont incohérents',
  'form.email.validate': "Le format de l'e-mail est incorrect",
  'form.password.validate': 'Le mot de passe est une combinaison de 8 à 16 lettres majuscules et minuscules, chiffres ou symboles',

  //作业、题目
  'exercise.Multiple-choice.question': 'Questions à choix multiples',
  'exercise.Multiple.choice.question': 'questions à choix multiples',
  'exercise.true.false questions': 'question vraie ou fausse',
  'exercise.subjective.questions': 'Questions subjectives',
  'exercise.reading.comprehension.questions': 'compréhension écrite',
  'exercise.reading.correct.answer': 'bonne réponse',
  'exercise.reading.your.answer': 'Votre Réponse',
  'exercise.reading.view.parsing': "Voir l'analyse",
  'exercise.reading.next.question': 'Question suivante',
  'exercise.reading.pack.up': 'fermer',

  //接口返回码
  'code.1001': "Le format de l'e-mail est incorrect, veuillez saisir la bonne adresse e-mail",
  'code.1004': 'Le code de vérification est incorrect',
  'code.1003': 'Cette adresse email est déjà enregistrée, veuillez modifier votre adresse email',
  'code.1011': 'Mot de passe modifié avec succès',
  'code.1010': "Le mot de passe d'origine est incorrect",
  'code.1009': 'Informations modifiées avec succès',
  'code.1013': 'Réinitialisation du mot de passe réussie',
  'code.1006': "Nom d'utilisateur ou mot de passe incorrect",
  'code.1014': 'Félicitations, votre achat de cours est réussi',
  'code.1016': 'Votre demande a été reçue',
  'code.1017': 'Commentaires soumis',
  'code.1019': 'Votre devoir a été soumis, veuillez vous rendre dans «Mes devoirs» pour le consulter.',
  //激活
  'code.1028': 'Activation du cours réussie',
  'code.1027': "Erreur de cours d'activation",
  'code.1026': "Le code d'activation a été utilisé",
  'code.1025': "Le code d'activation n'existe pas",

  //中华文化
  'home.latest.news': 'culture chinoise',
  'culture.center.more': 'Plus de nouvelles',
  'culture.center.subTitle': 'Laissez la Chine aller vers le monde et laissez le monde comprendre la Chine',
  'culture.center.search': 'Veuillez saisir les mots-clés que vous devez rechercher',
  'culture.center.text': 'centre culturel',
  'culture.center.btn': 'vérifier les détails',
  'hzf.login.hint': 'indice',
  'detail.course.download.anytime': 'Téléchargement de la pièce jointe',
  'hzf.login.agree': 'Acceptez et connectez-vous',
  'hzf.login.pre.read': 'Veuillez lire et accepter avant de vous connecter',
  'hzf.register.pre.read': 'Veuillez lire et accepter avant de vous inscrire',
  'hzf.login.agree.register': 'Acceptez et inscrivez-vous',

  //词典新增
  'dictionaries.glossary.1': 'Dictionnaire hanzfeng',
  'dictionaries.glossary.2': 'Explorez le monde chinois et illuminez votre voyage linguistique',
  'dictionaries.glossary.3': 'Centre Cihai',
  'dictionaries.glossary.4': 'Veuillez saisir le mot que vous souhaitez interroger',
  'dictionaries.glossary.6': 'Pinyin',
  'dictionaries.glossary.7': 'Exemples de phrases',
  'dictionaries.glossary.8': 'Anglais',
  'dictionaries.glossary.9': 'Termes de recherche',
  'dictionaries.glossary.10': 'Niveau 1',
  'dictionaries.glossary.11': 'Niveau 2',
  'dictionaries.glossary.12': 'Niveau 3',
  'dictionaries.glossary.13': 'Niveau 4',
  'dictionaries.glossary.14': 'Niveau 5',
  'dictionaries.glossary.15': 'Niveau 6',
  'currency.symbol': '$',
  //动态替换语言
  'hzf.variable.word': 'Anglais',

  'activation.code.text01': "Activation du code d'activation",
  'activation.code.text02': "Entrez le code d'activation pour activer le cours",
  'activation.code.text03': "Veuillez entrer votre code d'activation",
  'activation.code.text04': 'Allez acheter',
  'activation.code.text05': 'Activer maintenant',
  'activation.code.text06': 'Félicitations! Activation réussie!',
  'activation.code.text07': 'A regarder la prochaine fois',
  'activation.code.text08': "Vous avez activé avec succès le cours de chinois, venez en faire l'expérience~",

  'video.reload.text': 'revoir',
  'qrcode.text': "Scannez et téléchargez l'application",

  //我们的优势
  'swiperList.01.title': 'Efficacité et qualité de l’enseignement',
  'swiperList.01.desc': 'Aider les enseignants à optimiser les stratégies d’enseignement et à améliorer la qualité de l’enseignement',
  'swiperList.01.desc01':
    "Les enseignants peuvent publier du matériel pédagogique, assigner des devoirs, corriger les devoirs, etc. via des sites Web éducatifs pour réduire le travail répétitif et améliorer l'efficacité du travail.",
  'swiperList.02.title': 'Un apprentissage flexible et pratique',
  'swiperList.02.desc': "Le chinois hanzfeng n'est pas limité par le temps et l'espace de l'éducation traditionnelle",
  'swiperList.02.desc01':
    "hanzfeng Chinese fournit une multitude de ressources en ligne auxquelles les étudiants peuvent accéder et commencer à apprendre à tout moment, sans avoir à attendre le début des cours ou à planifier une heure d'étude spécifique.",
  'swiperList.03.title': 'Notre équipe',
  'swiperList.03.desc': "hanzfeng Chinese est composé d'un groupe d'élites de nombreux domaines",
  'swiperList.03.desc01':
    "hanzfeng Chinese est composé d'un groupe d'élites issues de l'éducation, de la technologie, du design et d'autres domaines. Non seulement ils possèdent des connaissances professionnelles approfondies, mais ils sont également passionnés et responsables de l'éducation. Chaque maillon incarne le travail acharné et la sagesse des membres de l'équipe.",
  'swiperList.04.title': 'Interactions et communications',
  'swiperList.04.desc': 'Maintenir une interaction en temps réel avec les étudiants des cours de chinois hanzfeng',
  'swiperList.04.desc01':
    "hanzfeng Chinese maintient une interaction en temps réel avec les étudiants dans ses cours et complète une boucle fermée d'apprentissage par le biais de questions et d'exercices après les cours pour garantir les résultats d'apprentissage des étudiants.",
  'swiperList.05.title': "De riches ressources d'apprentissage personnalisées",
  'swiperList.05.desc': 'hanzfeng Chinese rassemble diverses ressources éducatives chinoises',
  'swiperList.05.desc01':
    "hanzfeng Chinese rassemble toutes sortes de ressources éducatives chinoises grâce à un système de recommandation intelligent, le site Web éducatif peut recommander des contenus et des parcours d'apprentissage personnalisés aux étudiants en fonction de leurs progrès d'apprentissage, de leurs intérêts et de leurs préférences afin d'améliorer les effets d'apprentissage.",

  //直播课
  'live.message.01': 'Cours en direct',
  'live.message.02': 'Détails du cours en direct',
  'live.message.03': 'heure de Pékin',
  'live.message.04': 'Professeur en direct',
  'live.message.05': 'diffusion en direct',
  'live.message.06': 'Heure de début des cours',
  'live.message.07': 'Diffusion en direct premium',
  'live.message.08': 'Enseignement par des professeurs célèbres',
  'live.message.09': 'Interaction en temps réel',
  'live.message.10': 'Détails de la diffusion en direct',
  'live.message.11': 'Autres cours en direct',
  'live.message.12': "Il n'y a actuellement aucun cours en direct prévu",

  //注册加国家
  // 'registry.message.01': '国家信息一经确定不可更改！',
  // 'registry.message.02': '请选择您的国家',
  // 'registry.message.03': '国家',

  //支付
  'payment.message.01': 'Mode de paiement',
  'payment.message.02': "Durée d'achat",
  'payment.message.03': "Le paiement a échoué! S'il vous plaît payer à nouveau",
  'payment.message.04': 'un mois',
  'payment.message.05': 'trois mois',
  'payment.message.06': 'six mois',
  'payment.message.07': 'un an',
  'payment.message.08': '2 ans',
  //添加欧元币种
  'payment.message.09': 'Devise de paiement',
  'payment.message.10': 'Dollar',
  'payment.message.11': 'Eeuro',
  'payment.message.12': '€',
  //支付窗口修改
  'payment.message.13': 'Astuces',
  'payment.message.14': 'PayPal est la méthode de paiement actuellement prise en charge et il y aura de légères différences selon les pays.',
  //购买须知
  'payment.message.15': '"Instructions d\'achat"',
  'payment.message.16': "Instructions d'achat",
  'payment.message.17': 'Je suis déjà informé',
  'payment.message.18': "Veuillez d'abord lire et accepter",

  //邀请好友
  'share.message.01': 'Inviter des amis',
  'share.message.02': 'Copier maintenant',
  'share.message.03': 'Copié avec succès',
  'share.message.04': "Liste d'invitations",
  'share.message.05': 'Compte invité',
  'share.message.06': "Heure d'inscription",

  //版权所有，盗摄必究
  'copyright.message.01': 'Tous droits réservés',
  'copyright.message.02': 'Protection juridique',

  //网站名称
  'website.message.01': 'hanzfeng Chinois',

  //套餐
  'setMeal.message.01': 'Plus de forfaits disponible',
  'setMeal.message.02': 'Forfait',
  'setMeal.message.03': '{value} séries de cours de chinois inclus',
  'setMeal.message.04': `<span>{value}</span> personnes ont déjà acheté`,
  'setMeal.message.05': 'Déjà cheté',
  'setMeal.message.06': 'Prix du forfait',
  'setMeal.message.07': 'Autres forfaits recommandés',
  'setMeal.message.08': 'Cours inclus',
  'setMeal.message.09': 'Tous les forfaits',
  'setMeal.message.10': "Félicitations, l'achat de votre forfait de cours été éffectué avec succès!",
  'setMeal.message.11': 'Forfait de cours',
  //新增套餐
  'setMeal.message.12': 'Le quota de cours est inclus dans le forfait',
  'setMeal.message.13': '{value} heures de cours',
  'setMeal.message.14': 'Mon forfait',
  'setMeal.message.15': 'Le quota de cours pour ce forfait a été épuisé, il est impossible de débloquer ce cours.',
  'setMeal.message.16': 'Quota utilisé',
  'setMeal.message.17': 'Solde restant',
  'setMeal.message.18': "Vous avez acheté d'autres forfaits",

  //我的课程-剩余有效期
  'validity.message.01': 'Durée de validité restante',
  'validity.message.02': '{value} jours',

  //查看更多
  'cultural.message.01': 'Voir plus',

  //添加邮箱
  'email.message.01': "Consultation d'avant-vente",
  'email.message.02': "E-mail d'avant-vente",
  'email.message.03': "E-mail d'après-vente",

  //客服悬浮框
  'callCenter.message.01': 'Consultation',
  //营销弹框
  'marketing.message.01': 'Aller écouter',
  'marketing.message.02': 'Aide social',
  'marketing.message.03': 'Grosse livraison',
  'marketing.message.04': 'Les nouveaux utilisateurs peuvent profiter de 14 sessions de cours gratuitement sur 7 jours !',
  'marketing.message.05': 'Obtenez-le maintenant',
  'marketing.message.06': 'Écouter maintenant',

  //与优化
  'chapter.message.01': 'Déjà regardé',
};
