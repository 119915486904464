import { reactive } from 'vue';
import { defineStore } from 'pinia';

/**
 * 缓存在本地的
 */
export const useCommonCacheStore = defineStore('commonCacheStore', () => {
  const headerTheme = reactive({
    backgroundColor: '#FFFFFF',
    textColor: '#000000',
    lineColor: '#0678FF',
    loginBtnBorderColor: 'rgba(153,153,153,0.6)',
    loginBtnColor: '#999999',
    registerBtnBgColor: '#0678FF',
    registerBtnColor: '#FFFFFF',
  });

  const setHeaderWhiteTheme = () => {
    headerTheme.backgroundColor = '#FFFFFF';
    headerTheme.textColor = '#000000';
    headerTheme.lineColor = '#0678FF';
    headerTheme.loginBtnBorderColor = 'rgba(153,153,153,0.6)';
    headerTheme.loginBtnColor = '#999999';
    headerTheme.registerBtnBgColor = '#0678FF';
    headerTheme.registerBtnColor = '#FFFFFF';
  };

  const setHeaderDefaultTheme = () => {
    headerTheme.backgroundColor = 'transparent';
    headerTheme.textColor = '#FFFFFF';
    headerTheme.lineColor = '#FFFFFF';
    headerTheme.loginBtnBorderColor = 'rgba(255,255,255,0.6)';
    headerTheme.loginBtnColor = '#FFFFFF';
    headerTheme.registerBtnBgColor = 'rgba(255,255,255,0.5)';
    headerTheme.registerBtnColor = '#FFFFFF';
  };

  return {
    headerTheme,
    setHeaderWhiteTheme,
    setHeaderDefaultTheme,
  };
});
