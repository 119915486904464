// 定义英文语言包对象
export default {
  //公共部分
  'common.cancel': 'Cancel',
  'common.confirm': 'OK',
  'common.picture.cropping': 'Picture cropping',
  'common.course.tags': 'Course label',
  'common.all.course': 'Full course',
  'common.article.detail': 'Article details',
  'common.article.public.date': 'Release date',
  'common.article.pre': 'Previous post',
  'common.article.next': 'Next chapter',
  'common.reset.password': 'Your password has been reset, please login now',
  'common.back.home': 'Back to Home',
  'common.practice.after.class': 'Practice after class',
  'common.practice.no.after.class': 'No after-school practice',
  'common.next.course': 'Next',
  'common.pre.course': 'Previous',
  'common.stop.course': 'Stop',
  'common.homework.title': 'Job Submitted',
  'common.homework.teacher.comments': "Teacher's comments",
  'common.all.course.charge': 'pay',
  'common.all.course.free': 'free',
  'common.read.agreement': 'Please read and check the User Agreement',
  'common.protokol.privasi01': `I have read and agree`,
  'common.protokol.privasi02': `《User agreement》`,
  //头部
  'header.manifesto_01': 'Learn Chinese',
  'header.manifesto_02': 'Han Style Chinese',
  'header.menu.homePage': 'Home',
  'header.menu.dictionaries': 'Dictionaries',
  'header.menu.allChannels': 'All',
  'header.login': 'Log in',
  'header.register': 'Sign up',
  'header.search.btn': 'Search',

  //首页
  'home.ourAdvantage': 'Our advantage',
  'home.hotline': 'Email',
  'home.contact.immediately': 'Contact now',
  'home.back.top': 'Back to the top',
  'home.view.now': 'View excellent courses now',
  'home.learn.count': 'people study in Han Style Chinese',
  'home.unit.desc': 'We are a world-renowned university specializing in international Chinese language education. We are the pioneer, founder and leader of international Chinese language education.',
  'home.data.statistics': 'The statistics are provided by Han Style Chinese Academy',
  'home.recommended.course': 'Recommended course',
  'home.recommended.cost-effective': 'High cost performance',
  'home.recommended.high.quality': 'High quality',
  'home.go.to': 'Go Now',
  'home.learned.count': '{value} people have learned',
  'home.course.more': 'See more',
  'home.change.course': 'refresh',
  'home.price.free': 'free',
  //底部
  'footer.unit.desc':
    'Focusing on cross-border distance Chinese international education and international promotion of Chinese culture, the college has trained about 10,000 overseas paying students from more than 110 countries and regions around the world.',
  'footer.chinese.course': 'Chinese course',
  'footer.about.HanFeng': 'About Han Style Chinese',
  'footer.about.us': 'About us',
  'footer.privacy.agreement': 'User agreement',
  'footer.problem.feedback': 'Problem feedback',
  'footer.global.consulting': 'Global enquiry/complaint hotline',
  'footer.report.mailbox': 'Service Email',
  'footer.copyright': 'Copyright © 2024 安徽汉之风教育科技有限公司版权所有 {value}：皖ICP备2024055290号',
  'footer.copyright.text': `Website record number`,
  //个人中心
  'individual.center': 'Individual center',
  'individual.center.my.homework': 'My Homework',
  'individual.center.my.course': 'My Course',
  'individual.center.my.collection': 'My Collection',
  'individual.center.log.out': 'Log Out',
  'individual.center.logout.confirm': "Are you sure you're out?",
  'individual.update.password': 'Change Password',
  'individual.center.nickname': 'Nickname',
  'individual.center.submit': 'Submit ',
  'individual.center.origin.password': 'Old Password',
  'individual.center.new.password': 'New Password',
  'individual.center.confirm.password': 'Confirm Password',
  'individual.center.all': 'All',
  'individual.center.studying': 'Studying',
  'individual.center.finished': 'Finished',
  'individual.center.correcting': 'Be Correcting',
  'individual.center.corrected': 'Corrected',
  'individual.center.wart': 'You are currently undergoing homework correction, please wait patiently for the correction.',
  'individual.favorite.modal.title': 'uncollect',
  'individual.favorite.modal.content': 'Are you sure you want to uncollect',
  //登录弹框
  'login.welcome': 'Welcome to login',
  'login.welcome.desc': 'Welcome to Han Style Chinese learning platform',
  'login.forgot.password': 'Forgot password',
  'login.sign.now': 'Log in',
  'login.no.account': 'No account',
  'login.register.now': 'Register now',
  'login.register.success': 'Registered successfully',
  'login.register.success.desc': 'Congratulations, registration is successful',
  'login.not.login': 'Not logged in',
  'login.not.login.desc': 'You are not logged in yet, please log in first',
  //注册弹框
  'register.welcome': 'Welcome to register',
  'register.fetch.code': 'Get verification code',
  'reset.come.mind': 'Come to mind',
  //未购买弹框
  'buy.dialog.title': 'You have not purchased the course yet, so you cannot watch it. Please purchase the course first.',
  'buy.dialog.look': 'Take another look',
  //意见反馈弹框
  'feedback.text': "If you're a product manager, what are your ideas?",
  'feedback.problem': 'Present problem',
  'feedback.problem.placeholder': 'Talk about the specific problem you encountered, the specific scenario',
  'feedback.btn.cancel': 'Submit later',

  //题目反馈弹框
  'topic.btn.misspellings': 'Contains misspellings',
  'topic.btn.audio.error': 'Audio error',
  'topic.btn.not.exist': 'The picture does not exist',
  'topic.btn.incomplete': 'Incomplete topic',
  'topic.btn.other': 'Other questions',
  'topic.problem': 'Specific feedback content',
  'topic.problem.text': 'Feedback Problem',
  //试听结束弹框
  'try.over.title': 'End of audition',
  'try.over.desc': 'Your current free demo has ended, please purchase the course to continue watching.',
  //视频课详情
  'detail.course.classify': 'Course classification',
  'detail.tag.video': 'video',
  'detail.lessons.number': '{value}lessons',
  'detail.course.price': 'Price',
  'detail.course.characteristic.service': 'Special Service ',
  'detail.course.brush.questions': 'Question-solving training',
  'detail.course.online.consultation': 'Online consultation',
  'detail.course.buy.now': 'Buy Now',
  'detail.course.study.now': 'Learn Now',
  'detail.course.consult.now': 'Consult now',
  'detail.course.course.details': 'Course details',
  'detail.course.course.chapter': 'Course chapter',
  'detail.course.other.recommendations': 'Other course recommendations',
  'detail.course.buy.title': 'You are purchasing a Chinese language course at Han Style Chinese Academy',
  'detail.welcome.consult': 'Welcome to consult',
  'detail.consult.tips': 'After leaving your contact information, we will have a professional teacher contact you',
  'detail.records.now': 'Register Now',
  'detail.records.Play': 'Play back',
  'detail.records.try': 'Trial seeding',
  'detail.course.collect': 'Collect',
  'detail.course.uncollect': 'Uncollect',

  //视频播放页
  'player.course.collect': 'Collecting course',
  'player.course.download.attachment': 'Download attachment',
  'player.course.catalogue': 'catalogue',
  'player.course.electronic.textbook': 'Electronic textbook',
  'player.course.electronic.word': '【 Word practice 】 Basic practice words (dubbing, pronunciation, etc.)',
  'player.course.chinese.pinyin': 'Chinese pinyin',
  'player.course.indonesian': 'Indonesian',
  'player.course.chinese': 'Chinese',
  'player.course.close.pinyin': 'Close pinyin',
  'player.course.open.pinyin': 'Open pinyin',
  'player.course.electronic.sentence': '【 Essay Exercises 】 Basic exercises (essays, short sentences, etc.)',
  'player.course.exercise': 'Course exercise',

  //练习结果页
  'practiceResult.count.questions': 'Problem Total',
  'practiceResult.count.questions.unanswered': 'Unanswered Questions',
  'practiceResult.answer.sheet': 'Answer sheet',
  'practiceResult.answer.correct': 'Right',
  'practiceResult.answer.error': 'Wrong',
  'practiceResult.answer.unanswered': 'Null',
  'practiceResult.answer.answered': 'answered',
  'practiceResult.skip.practice': 'Skip practice',
  'practiceResult.full.mark': 'Out of {value}',
  'practiceResult.submit.now': 'Submit now',

  //课程分类
  'all.course.find': `A total of <span>{value}</span> results were found`,
  'all.course.sort.synthesis': 'synthesis',
  'all.course.sort.popularity': 'popularity',

  //表单
  'form.placeholder.email': 'Please enter your email address',
  'form.placeholder.password': 'Please enter your password',
  'form.placeholder.confirm.password': 'Please confirm your password',
  'form.placeholder.code': 'Please enter your verification code',
  'form.placeholder.nickname': 'Please enter a nickname',
  'form.placeholder.origin.password': 'Please enter the old password',
  'form.placeholder.new.password': 'Please enter your new password',
  'form.placeholder.new.username': 'Please enter your name',
  'form.placeholder.new.mobile': 'Please enter your phone number',
  'form.placeholder.search': 'Search Chinese Courses',
  'form.placeholder.textarea': 'Please answer here',
  'form.reset.password': 'Reset password',
  'form.has.account': 'Existing account',

  //校验
  'form.password.unlikeness': 'The two passwords are different',
  'form.email.validate': 'The mailbox format is incorrect',
  'form.password.validate': 'The password is a combination of 8-16 uppercase and lowercase letters, numbers, or symbols',

  //作业、题目
  'exercise.Multiple-choice.question': 'Multiple-choice question',
  'exercise.Multiple.choice.question': 'Multiple-choice question',
  'exercise.true.false questions': 'True or false question',
  'exercise.subjective.questions': 'Subjective question',
  'exercise.reading.comprehension.questions': 'Reading comprehension',
  'exercise.reading.correct.answer': 'Correct answer',
  'exercise.reading.your.answer': 'Your answer',
  'exercise.reading.view.parsing': 'View analysis',
  'exercise.reading.next.question': 'Next question',
  'exercise.reading.pack.up': 'Collapse',

  //接口返回码
  'code.1001': 'The email format is incorrect. Please enter the correct email address',
  'code.1004': 'The verification code is incorrect',
  'code.1003': 'The mailbox has been registered, please change the mailbox',
  'code.1011': 'Password changed successfully',
  'code.1010': 'The old password is incorrect',
  'code.1009': 'Information modified successfully',
  'code.1013': 'Reset password successfully',
  'code.1006': 'The user name or password is incorrect',
  'code.1014': 'Congratulations, the course purchase is successful.',
  'code.1016': 'We have received your enquiry',
  'code.1017': 'Feedback submitted',
  'code.1019': 'Your assignment has been submitted, please go to "My homework" to view',
  //激活
  'code.1028': 'The course was successfully activated',
  'code.1027': 'Activation course error',
  'code.1026': 'The activation code has been used',
  'code.1025': 'The activation code does not exist',

  //中华文化
  'home.latest.news': 'Chinese culture',
  'culture.center.more': 'More news',
  'culture.center.subTitle': 'Let China go to the world and let the world understand China',
  'culture.center.search': 'Please enter the keyword you want to search for',
  'culture.center.text': 'Cultural center',
  'culture.center.btn': 'Find out more',
  'hzf.login.hint': 'prompt',
  'detail.course.download.anytime': 'Attachment download',
  'hzf.login.agree': 'Agree and log in',
  'hzf.login.pre.read': 'Please read and agree before logging in',
  'hzf.register.pre.read': 'Please read and agree before registering',
  'hzf.login.agree.register': 'Agree and register',
  //词典新增
  'dictionaries.glossary.1': 'Dictionary of  Han Style Chinese',
  'dictionaries.glossary.2': 'Explore the world of Chinese and light up your language path',
  'dictionaries.glossary.3': 'The center of the sea of words',
  'dictionaries.glossary.4': 'Please enter the word you want to inquire about',
  'dictionaries.glossary.6': 'Pinyin',
  'dictionaries.glossary.7': 'Example',
  'dictionaries.glossary.8': 'English',
  'dictionaries.glossary.9': 'Search for vocabulary',
  'dictionaries.glossary.10': 'Level 1',
  'dictionaries.glossary.11': 'Level 2',
  'dictionaries.glossary.12': 'Level 3',
  'dictionaries.glossary.13': 'Level 4',
  'dictionaries.glossary.14': 'Level 5',
  'dictionaries.glossary.15': 'Level 6',

  'currency.symbol': '$',

  //动态替换语言
  'hzf.variable.word': 'English',

  'activation.code.text01': 'Activate the activation code',
  'activation.code.text02': 'Enter the activation code to activate the course',
  'activation.code.text03': 'Please enter your activation code',
  'activation.code.text04': 'Go to Buy',
  'activation.code.text05': 'Activate now',
  'activation.code.text06': 'Congratulations! Activation Successful!',
  'activation.code.text07': "I'll see it next time",
  'activation.code.text08': 'You have successfully activated the Chinese course, come and experience it~',

  'video.reload.text': 'Watch again',
  'qrcode.text': 'APP Download',

  //我们的优势
  'swiperList.01.title': 'Teaching efficiency and quality',
  'swiperList.01.desc': 'Help teachers optimize teaching strategies and improve teaching quality',
  'swiperList.01.desc01':
    'Teachers can publish teaching materials, assign homework, and correct homework through educational websites, thereby reducing repetitive work and improving work efficiency.',
  'swiperList.02.title': 'Flexible and convenient learning',
  'swiperList.02.desc': 'Han Style Chinese is not limited by the time and space of traditional education.',
  'swiperList.02.desc01':
    'Han Style Chinese provides a wealth of online resources that students can access and start learning at any time without having to wait for classes to start or arrange a specific study time.',
  'swiperList.03.title': 'our team',
  'swiperList.03.desc': 'Han Style Chinese is composed of a group of elites from various fields.',
  'swiperList.03.desc01':
    "Han Style Chinese is composed of a group of elites from various fields such as education, technology, design, etc. They didn't only have profound professional knowledge, but also are full of passion and sense of responsibility for education. Every link embodies the hard work and wisdom of team members.",
  'swiperList.04.title': 'Interaction and communication',
  'swiperList.04.desc': 'In Han Style Chinese courses we maintain real-time interaction with students',
  'swiperList.04.desc01':
    "Han Style Chinese courses maintain real-time interaction with students, and complete the learning loop through questions and after-class exercises to ensure students' learning effectiveness.",
  'swiperList.05.title': 'Rich personalized learning resources',
  'swiperList.05.desc': 'Han Style Chinese brings together various Chinese education resources',
  'swiperList.05.desc01':
    'Han Style Chinese brings together various Chinese education resources. Through the intelligent recommendation system, the education website can recommend personalized learning content and paths to students based on their learning progress, interest preferences and other information to improve learning outcomes.',

  //直播课
  'live.message.01': 'Live classes',
  'live.message.02': 'Details of the live class',
  'live.message.03': 'Beijing Time',
  'live.message.04': 'Live Teachers',
  'live.message.05': 'live broadcast',
  'live.message.06': 'Start time',
  'live.message.07': 'Excellent courses',
  'live.message.08': 'Teaching by famous teachers',
  'live.message.09': 'Real-time interaction',
  'live.message.10': 'Live stream details',
  'live.message.11': 'Other live classes',
  'live.message.12': 'There are currently no live classes.',

  //注册加国家
  // 'registry.message.01': 'Once the country information is confirmed, it cannot be changed! ',
  // 'registry.message.02': 'Please select your country',
  // 'registry.message.03': 'country',

  //支付
  'payment.message.01': 'Payment Method',
  'payment.message.02': 'Purchase Period',
  'payment.message.03': 'Payment failed! Please repay',
  'payment.message.04': 'One month',
  'payment.message.05': 'Three months',
  'payment.message.06': 'Six months',
  'payment.message.07': 'One year',
  'payment.message.08': 'Two years',
  //添加欧元币种
  'payment.message.09': 'Payment currency',
  'payment.message.10': 'Dollar',
  'payment.message.11': 'Euro',
  'payment.message.12': '€',
  //支付窗口修改
  'payment.message.13': 'warm reminder',
  'payment.message.14': 'Through PayPal, the above payment methods are currently supported, and there will be slight differences depending on the country. ',
  //购买须知
  'payment.message.15': '《buying notice》',
  'payment.message.16': 'buying notice',
  'payment.message.17': 'I already know it',
  'payment.message.18': 'read and agree',

  //邀请好友
  'share.message.01': 'Invite friends',
  'share.message.02': 'Copy now',
  'share.message.03': 'Copied successfully',
  'share.message.04': 'Invitation list',
  'share.message.05': "Invitee's account number",
  'share.message.06': 'Time of registration',

  //版权所有，盗摄必究
  'copyright.message.01': 'All rights reserved',
  'copyright.message.02': 'Theft must be investigated',

  //网站名称
  'website.message.01': 'Han Style Chinese',

  //套餐
  'setMeal.message.01': 'More packages',
  'setMeal.message.02': 'package',
  'setMeal.message.03': 'include {value} sets of Chinese courses',
  'setMeal.message.04': `<span>{value}</span> people have purchased`,
  'setMeal.message.05': 'Purchased',
  'setMeal.message.06': 'Package price',
  'setMeal.message.07': 'Other packages are recommended',
  'setMeal.message.08': 'Lessons included',
  'setMeal.message.09': 'All packages',
  'setMeal.message.10': 'Congratulations, the course package purchase is successful!',
  'setMeal.message.11': 'Course Packages',
  //新增套餐
  'setMeal.message.12': 'This course credit is included in the package',
  'setMeal.message.13': '{value}class hours',
  'setMeal.message.14': 'My Package',
  'setMeal.message.15': 'The course quota for this course package has been used up and the course cannot be unlocked.',
  'setMeal.message.16': 'Credits used',
  'setMeal.message.17': 'Remaining credit',
  'setMeal.message.18': 'you already bought other packages ',

  //我的课程-剩余有效期
  'validity.message.01': 'left expiration date',
  'validity.message.02': '{value} days',

  //查看更多
  'cultural.message.01': 'See more',

  //添加邮箱
  'email.message.01': 'Pre-sales consultation',
  'email.message.02': 'Pre-sale mailbox',
  'email.message.03': 'After-sales mailbox',

  //客服悬浮框
  'callCenter.message.01': 'consultation',
  //营销弹框
  'marketing.message.01': 'Go to the audition',
  'marketing.message.02': 'welfare',
  'marketing.message.03': 'Big delivery',
  'marketing.message.04': 'New users can enjoy 7 days of 14 free lessons!',
  'marketing.message.05': 'Get the lectures',
  'marketing.message.06': 'Start lectures now',

  //与优化
  'chapter.message.01': 'Already read',
};
